import { formatValue } from "react-currency-input-field";

export function formatCents(cents: number): string {
  return formatValue({
    value: (cents / 100).toString(),
    decimalSeparator: ".",
    decimalScale: 2,
    prefix: "$",
  });
}
