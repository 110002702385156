import { Link as MuiLink } from "@mui/material";
import { LinkProps, Link as ReactRouterLink, useLocation } from "react-router-dom";
import { useModuleContext } from "types/context";

const Link = (props: LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const { search } = useLocation();
  const moduleContext = useModuleContext();

  let to = props.to;
  if (moduleContext) {
    if (typeof to === "string") {
      to = `/${moduleContext.module}${to}`;
    } else if (typeof to === "object") {
      to.pathname = `/${moduleContext.module}${to.pathname}`;
    }
  }

  if (typeof to === "string") {
    to += search;
  } else if (typeof to === "object") {
    to.search = search;
  }

  return <MuiLink {...props} component={ReactRouterLink} to={to} />;
};

export default Link;
