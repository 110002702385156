import { Box, Button, Dialog, DialogActions, Paper, Stack, Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import axios from "axios";
import { JobStatus } from "modules/site-operator/pumps/records";
import { useEffect, useState } from "react";
import { sec } from "utils/security";

export function ViewProcessor() {
  const [jobStatus, setJobStatus] = useState<JobStatus>({
    id: -1,
    name: "",
    status: "",
  });
  const PumpProcessor = () => {
    async function pump_processor() {
      const token = await sec.getAccessTokenSilently();
      const response = await axios.get<JobStatus>(
        `${process.env.REACT_APP_API_BASE_URL}/pump-processor`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTimeout(() => {
        setJobStatus(response.data);
      }, 1000);
    }
    pump_processor();
  };
  useEffect(() => {
    PumpProcessor();
  }, []);
  const [open, setOpen] = useState(false);
  return jobStatus.id !== -1 ? (
    <Grid2 xs={6}>
      <Paper elevation={1}>
        <Stack spacing={1}>
          <Box>
            <Typography variant="h3" margin={2}>
              Pump Processor
            </Typography>
            <Typography variant="body1" margin={2}>
              <b>ID:</b> {jobStatus.id}
            </Typography>
            <Typography variant="body1" margin={2}>
              <b>Name:</b> {jobStatus.name}
            </Typography>
            <Typography variant="body1" margin={2}>
              <b>Status:</b> {jobStatus.status}
            </Typography>
            <Typography variant="body1" margin={2}>
              <b>Last Updated:</b> {jobStatus.last_updated?.toString()}
            </Typography>
            <Box margin={2}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={PumpProcessor}
                  title="Update State of Job Status"
                >
                  Get
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpen(true)}
                  title="Update State of Job Status"
                >
                  Edit
                </Button>
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Paper>
      <EditProcessor
        editOpen={open}
        setEditOpen={setOpen}
        jobStatus={jobStatus}
        setJobStatus={setJobStatus}
      />
    </Grid2>
  ) : null;
}

type EditProcessorProps = {
  editOpen: boolean;
  setEditOpen: (open: boolean) => void;
  jobStatus: JobStatus;
  setJobStatus: (jobStatus: JobStatus) => void;
};

export function EditProcessor(props: EditProcessorProps) {
  const [jobStatus, setJobStatus] = useState<JobStatus>(props.jobStatus);
  const handleClose = () => props.setEditOpen(false);
  const UpdateJobStatusLocal = (event: SelectChangeEvent) => {
    const status = event.target.value as string;
    setJobStatus((j) => ({ ...j, status: status }));
  };
  const SaveJobStatus = () => {
    async function UpdateJobStatusServerSide() {
      const token = await sec.getAccessTokenSilently();
      const response = await axios.post<JobStatus>(
        `${process.env.REACT_APP_API_BASE_URL}/pump-processor/${jobStatus.name}`,
        jobStatus,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setJobStatus(response.data);
      props.setJobStatus(response.data);
      handleClose();
    }
    setTimeout(() => {
      UpdateJobStatusServerSide();
    }, 1000);
  };
  return (
    <div>
      <Dialog open={props.editOpen} onClose={() => props.setEditOpen(false)}>
        <DialogTitle>Edit Job Status</DialogTitle>
        <DialogContent>
          <DialogContentText>Updated the Job Status</DialogContentText>
          <Typography variant="body1" margin={2}>
            <b>ID:</b> {jobStatus.id}
          </Typography>
          <Typography variant="body1" margin={2}>
            <b>Name:</b> {jobStatus.name}
          </Typography>
          <Typography variant="body1" margin={2}>
            <b>Status:</b>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={jobStatus.status}
              label="Age"
              onChange={UpdateJobStatusLocal}
            >
              <MenuItem value={"Running"}>Running</MenuItem>
              <MenuItem value={"Stopped"}>Stopped</MenuItem>
              <MenuItem value={"Failed"}>Failed</MenuItem>
            </Select>
          </Typography>
          <Typography variant="body1" margin={2}>
            <b>Last Updated:</b> {jobStatus.last_updated?.toString()}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={SaveJobStatus}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
