import { Chip } from "@mui/material";
import { ChargingStationAuthorization } from "service/api-slice";
import { formatCents } from "utils/currency";

const AuthorizationStatusChip = ({
  auth,
  size,
}: {
  auth: ChargingStationAuthorization | null | undefined;
  size?: "small" | "medium";
}) => {
  if (!size) {
    size = "small";
  }
  let color: "info" | "error" | "warning" | "success" = "error";
  let variant: "outlined" | "filled" = "outlined";
  let label: string;

  switch (auth?.type) {
    case "Manual":
      switch (auth.status) {
        case "Pending":
          color = "warning";
          label = "Manual Authorizing...";
          break;
        case "Authorized":
          color = "success";
          label = "Manual Authorized";
          variant = "filled";
          break;
        case "Finalizing":
        case "Finalized":
          color = "success";
          label = "Manual";
          break;
      }
      break;
    case "Fleet":
      switch (auth.status) {
        case "Pending":
          color = "warning";
          label = "Fleet Authorizing...";
          break;
        case "Authorized":
          color = "success";
          label = "Fleet Authorized";
          variant = "filled";
          break;
        case "Finalizing":
        case "Finalized":
          color = "success";
          label = "Fleet";
          break;
      }
      break;
    case "CreditCardPreauth":
      switch (auth.status) {
        case "Pending":
          color = "warning";
          label = "CC Authorizing...";
          break;
        case "Authorized":
          color = "success";
          label = "CC Authorized";
          variant = "filled";
          break;
        case "Finalizing":
          if (!auth.invoice) {
            color = "error";
            label = "CC No Invoice";
            break;
          }
          color = "warning";
          label = `CC Collecting ${formatCents(auth.invoice.total)}`;
          break;
        case "Finalized": {
          if (!auth.invoice) {
            color = "error";
            label = "CC No Invoice";
            break;
          }
          const total = auth.invoice.total;
          if (auth.invoice.status === "paid") {
            const collected = auth.invoice.collected ?? 0;
            if (collected === total) {
              color = "success";
              label = `CC Paid ${formatCents(collected)}`;
            } else {
              color = "warning";
              label = `CC Paid ${formatCents(collected)} / ${formatCents(total)}`;
            }
          } else {
            color = "warning";
            label = `CC Collecting ${formatCents(total)}`;
          }
        }
      }
      break;
    case null:
    case undefined:
    case "None":
      color = "info";
      label = "No Authorization";
      break;

    default:
      color = "error";
      label = "Unexpected Authorization Type";
  }
  return <Chip size={size} variant={variant} color={color} label={label} />;
};

export default AuthorizationStatusChip;
