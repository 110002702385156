import { useTheme } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import MainCard from "components/MainCard";
import GravityTable from "components/table/GravityTable";
import { parseISO } from "date-fns";
import { useNavigateWithModule } from "hooks/useNavigateWithModule";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { ChargingStation, useGetChargingStationsQuery } from "service/api-slice";
import { useModuleContext } from "types/context";
import { FirmwareVersions } from "../maintenance/components/firmware-version";
import ChargingStationLastSeen from "./components/ChargingStationLastSeen";
import ChargingStationLastUsed from "./components/ChargingStationLastUsed";
import ChargingStationName from "./components/ChargingStationName";
import ChargingStationStatusIndicator, {
  chargingStationStatus,
} from "./components/ChargingStationStatusIndicator";
import PumpStateChip from "./components/PumpStateChip";

const ChargingStationsList = () => {
  const { module } = useModuleContext();
  const navigate = useNavigateWithModule(module);

  function navigateToChargingStation(chargingStation: ChargingStation) {
    navigate(`charging-stations/${chargingStation.id}`);
  }

  return (
    <MainCard content={false}>
      <ChargingStationTable onClick={navigateToChargingStation} />
    </MainCard>
  );
};

const columnHelper = createColumnHelper<ChargingStation>();

const columns = [
  columnHelper.accessor("id", {
    header: () => <FormattedMessage id="id" />,
    meta: { showOnMobile: false, hidden: true },
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor("name", {
    cell: (i) => <ChargingStationName chargingStation={i.row.original} />,
    header: () => <FormattedMessage id="name" />,
    meta: { showOnMobile: true },
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor(
    (row) => (row.status?.last_used ? new Date(row.status.last_used) : undefined),
    {
      id: "last_used",
      cell: (i) => <ChargingStationLastUsed chargingStation={i.row.original} />,
      header: () => <FormattedMessage id="last-used" />,
      meta: { showOnMobile: true },
      sortingFn: "datetime",
    }
  ),
  columnHelper.accessor("model", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="model" />,
    meta: { showOnMobile: false },
  }),
  columnHelper.accessor("serial_number", {
    cell: (i) => i.getValue(),
    header: () => <FormattedMessage id="serial-number" />,
    meta: { showOnMobile: false, hidden: true },
  }),
  columnHelper.accessor("firmware_version", {
    cell: (i) => <FirmwareVersions versions={i.getValue()} />,
    header: () => <FormattedMessage id="firmware-versions" />,
    meta: { showOnMobile: false, hidden: true },
  }),
  columnHelper.accessor("approved", {
    cell: (i) => (i.getValue() ? "True" : "False"),
    header: () => <FormattedMessage id="approved" />,
    meta: { showOnMobile: false, hidden: true },
  }),
  columnHelper.accessor("pump_state", {
    cell: (i) => <PumpStateChip cooling={i.getValue()} />,
    header: () => <FormattedMessage id="cooling" />,
    meta: { showOnMobile: true },
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor((row) => chargingStationStatus(row), {
    id: "status",
    cell: (i) => <ChargingStationStatusIndicator chargingStation={i.row.original} />,
    header: () => <FormattedMessage id="status" />,
    meta: { showOnMobile: true },
  }),
  columnHelper.accessor(
    (row) => (row.status?.last_seen ? new Date(row.status.last_seen) : undefined),
    {
      id: "last_seen",
      cell: (i) => <ChargingStationLastSeen chargingStation={i.row.original} />,
      header: () => <FormattedMessage id="last-seen" />,
      meta: { showOnMobile: true },
      sortingFn: "datetime",
    }
  ),
];

export const ChargingStationTable = ({
  onClick,
  disableBusyStations,
}: {
  onClick: (chargingStation: ChargingStation) => void;
  disableBusyStations?: boolean;
  csFilter?: (chargingStation: ChargingStation) => boolean;
}) => {
  const theme = useTheme();
  const { filter } = useModuleContext();
  const { data, isLoading } = useGetChargingStationsQuery({
    siteId: filter.siteId,
    expand_authorization: disableBusyStations,
    expand_cooling: true,
    expand_current_transaction: true,
  });

  const isChargingStationBusy = useCallback(
    (chargingStation: ChargingStation) => {
      var isAuthorized = false;
      if (chargingStation.authorization?.valid_until) {
        isAuthorized = new Date() < parseISO(chargingStation.authorization.valid_until);
      }
      return (
        (disableBusyStations &&
          chargingStation.status?.status === "Occupied" &&
          chargingStation.current_transaction) ||
        isAuthorized
      );
    },
    [disableBusyStations]
  );

  return (
    <GravityTable<ChargingStation>
      id="cst"
      columns={columns}
      data={data?.data}
      isLoading={isLoading}
      initialSorting={[{ id: "name", desc: false }]}
      rowSx={(item) => {
        if (isChargingStationBusy(item)) {
          return { background: theme.palette.action.disabledBackground };
        }
      }}
      rowClickHandlerProvider={(item) => {
        if (!isChargingStationBusy(item)) {
          return onClick;
        }
        return null;
      }}
    />
  );
};

export default ChargingStationsList;
