import useLocalStorage from "hooks/useLocalStorage";
import isEqual from "lodash/isEqual";
import { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import { arraysEqual } from "utils/arrays";
import { FeatureFlag, FeatureFlagDefinition } from "./FeatureFlag";
import { Flags } from "./Flags";
import { useLaunchDarkly } from "./LaunchDarklyContext";

export type FeatureEvaluator = <T = boolean | string>(flag: FeatureFlagDefinition<T>) => T;

export type FeatureFlagContextProps = {
  featureFlags: FeatureFlag[];
  overrideFeature: (flag: FeatureFlag, value: string | boolean | undefined) => void;
  resetAllOverrides: () => void;
  evaluateFeature: FeatureEvaluator;
};

export type FeatureFlagProviderProps = {
  children: React.ReactNode;
};

const FeatureFlagContext = createContext<FeatureFlagContextProps>({
  featureFlags: [],
  overrideFeature: () => {},
  resetAllOverrides: () => {},
  evaluateFeature: (flag) => flag.defaultValue,
});

const FeatureFlagProvider = ({ children }: FeatureFlagProviderProps) => {
  const ld = useLaunchDarkly();
  const [featureFlags, setFeatureFlags] = useLocalStorage<FeatureFlag[]>("featureFlags", []);

  const listener = useCallback(() => {
    let ldFlags = ld.allFlags();
    console.log("LaunchDarkly flags", ldFlags);
    let madeChanges = false;
    let copy = featureFlags.slice();
    for (const k of Object.keys(Flags)) {
      const key = k as keyof typeof Flags;
      const flagDef = Flags[key];
      const flagIdx = copy.findIndex((f) => f.name === flagDef.name);
      console.log("Checking feature flag", flagDef.name, flagIdx);
      console.log("copy", copy);
      if (flagIdx === -1) {
        console.log(`Feature flag ${flagDef.name} added.`);
        madeChanges = true;
        copy.push({
          ...flagDef,
          definitionName: key,
          serverValue: ldFlags[flagDef.name],
          overriddenValue: undefined,
        });
      } else {
        if (copy[flagIdx].definitionName !== key) {
          console.log(`Feature flag ${copy[flagIdx].name} definition changed.`);
          madeChanges = true;
          copy[flagIdx].definitionName = key;
        }
        const serverValue = ldFlags[flagDef.name];
        if (!isEqual(copy[flagIdx].serverValue, serverValue)) {
          console.log(
            `Feature flag ${copy[flagIdx].name} server value changed.`,
            copy[flagIdx].serverValue,
            serverValue
          );
          madeChanges = true;
          copy[flagIdx].serverValue = serverValue;
        }
        if (!arraysEqual(copy[flagIdx].values, flagDef.values)) {
          console.log(`Feature flag ${copy[flagIdx].name} values changed.`);
          madeChanges = true;
          copy[flagIdx].values = flagDef.values;
        }
        if (!isEqual(copy[flagIdx].defaultValue, flagDef.defaultValue)) {
          console.log(`Feature flag ${copy[flagIdx].name} default value changed.`);
          madeChanges = true;
          copy[flagIdx].defaultValue = flagDef.defaultValue;
        }
      }
    }
    const length = copy.length;
    copy = copy.filter((f) => {
      const key = f.definitionName as keyof typeof Flags;
      const flagDef = Flags[key];
      return flagDef !== undefined && flagDef.name === f.name;
    });
    if (length !== copy.length) {
      console.log("Features flags were removed.");
      madeChanges = true;
    }
    if (madeChanges && !isEqual(featureFlags, copy)) {
      console.log("Updating new FeatureFlags", featureFlags, copy);
      setFeatureFlags(copy);
    } else {
      console.log("No changes detected in FeatureFlags");
    }
  }, [featureFlags, ld, setFeatureFlags]);

  useEffect(() => {
    ld.on("initialized", listener);
    ld.on("change", listener);
    return () => {
      ld.off("change", listener);
    };
  }, [listener, ld]);

  const memoFlags = useMemo(() => {
    return {
      featureFlags: featureFlags,
      overrideFeature: function <T extends string | boolean | undefined>(
        flag: FeatureFlag<T>,
        value: T
      ): void {
        const copy = featureFlags.slice();
        const ff = copy.find((f) => f.name === flag.name);
        if (ff === undefined) {
          console.warn(`Feature flag ${flag.name} is not registered.`);
          return;
        }
        console.log("Overriding FeatureFlag", ff, value);
        ff.overriddenValue = value;
        setFeatureFlags(copy);
      },
      resetAllOverrides: () => {
        const copy = featureFlags.slice();
        for (const ff of copy) {
          ff.overriddenValue = undefined;
        }
        console.log("Resetting FeatureFlags");
        setFeatureFlags(copy);
      },
      evaluateFeature: function <T>(flag: FeatureFlagDefinition<T>): T {
        const ff = featureFlags.find((f) => f.name === flag.name);
        if (ff === undefined) {
          console.warn(`Feature flag ${flag.name} is not registered.`);
          return flag.defaultValue;
        }
        const overriddenValue = ff.overriddenValue;
        if (overriddenValue !== undefined) {
          return overriddenValue as T;
        }
        const serverValue = ff.serverValue;
        if (serverValue !== undefined) {
          return serverValue as T;
        }
        return flag.defaultValue;
      },
    };
  }, [featureFlags, setFeatureFlags]);

  return <FeatureFlagContext.Provider value={memoFlags}>{children}</FeatureFlagContext.Provider>;
};

export const useFeatureFlags = (context = FeatureFlagContext): FeatureFlagContextProps =>
  useContext(context);

export default FeatureFlagProvider;
