import { DashboardOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { NavItemType } from "types/menu";

export const UtilityOperatorMenu: NavItemType = {
  id: "utility-operator-menu",
  type: "group",
  url: "/utility-operator",
  title: <FormattedMessage id="utility-operator-menu" />,
  breadcrumbs: true,
  children: [
    {
      id: "dashboard",
      title: <FormattedMessage id="overview" />,
      type: "item",
      url: "/utility-operator",
      icon: DashboardOutlined,
      breadcrumbs: false,
    },
  ],
};
