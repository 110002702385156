import {
  Button,
  DialogProps,
  ImageList,
  ImageListItem,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import CameraDialog from "components/camera/CameraDialog";
import { SyntheticEvent, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  useListEmployeeVerificationImagesQuery,
  useUploadEmployeeVerificationImageMutation,
} from "service/api-slice";
import { useModuleContext } from "types/context";
import { Module } from "types/modules";
import { NameAndContents, useFilePicker } from "components/FilePicker";

const UploadEmployeeVerificationImage = ({
  authorizationId,
  txId,
  csId,
  vehicleId,
}: {
  authorizationId?: string;
  txId?: string;
  csId?: string;
  vehicleId?: string;
}) => {
  const theme = useTheme();
  const { module } = useModuleContext();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);

  const [uploadEmployeeVerificationImage, uploadEmployeeVerificationImageResult] =
    useUploadEmployeeVerificationImageMutation();
  const { data: employeeVerificationImages, refetch } = useListEmployeeVerificationImagesQuery({
    authorization_id: authorizationId,
    transaction_id: txId,
    charging_station_id: csId,
    vehicle_id: vehicleId,
  });

  const uploadImages = useCallback(
    async (files: NameAndContents[]) => {
      const encodedFiles: string[] = [];
      for (const f of files) {
        const buffer = Buffer.from(f.contents);
        encodedFiles.push(buffer.toString("base64"));
      }

      await uploadEmployeeVerificationImage({
        files: encodedFiles,
        authorization_id: authorizationId,
        transaction_id: txId,
        charging_station_id: csId,
        vehicle_id: vehicleId,
      }).unwrap();
      refetch();
    },
    [refetch, uploadEmployeeVerificationImage, authorizationId, txId, csId, vehicleId]
  );
  const { onFilesPicked, clearFilesPicked } = useFilePicker(uploadImages);

  const handleCapturedImage = useCallback(
    async (blob: Blob) => {
      const arrayBuffer = await blob.arrayBuffer();
      const buffer = Buffer.from(arrayBuffer);
      await uploadEmployeeVerificationImage({
        files: [buffer.toString("base64")],
        authorization_id: authorizationId,
        transaction_id: txId,
        charging_station_id: csId,
        vehicle_id: vehicleId,
      }).unwrap();
      setOpen(false);
      clearFilesPicked();
      refetch();
    },
    [
      clearFilesPicked,
      refetch,
      uploadEmployeeVerificationImage,
      authorizationId,
      txId,
      csId,
      vehicleId,
      setOpen,
    ]
  );

  const handleClose: DialogProps["onClose"] = useCallback(
    (event: SyntheticEvent, reason) => {
      if (reason === "backdropClick") {
        event.stopPropagation();
        return;
      }
      setOpen(false);
      refetch();
    },
    [refetch, setOpen]
  );

  return (
    <>
      {module === Module.SiteOperator && (
        <CameraDialog
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          onImageCaptured={handleCapturedImage}
        />
      )}
      {employeeVerificationImages && (
        <ImageList sx={{ height: fullScreen ? 300 : 175 }} cols={2}>
          {employeeVerificationImages.data.map((image) => (
            <ImageListItem key={image.id}>
              <img alt="employee badge" src={`${image.signed_url}`} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>
      )}
      {module === Module.SiteOperator && (
        <Stack direction={"row"} justifyContent={"flex-end"} spacing={1} sx={{ padding: 1 }}>
          {uploadEmployeeVerificationImageResult.isLoading && <LinearProgress />}
          {!uploadEmployeeVerificationImageResult.isLoading && (
            <>
              <Button
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(true);
                }}
              >
                <FormattedMessage id="take-photo" />
              </Button>
              <form>
                <Button
                  component="label"
                  variant="outlined"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <FormattedMessage id="choose-photos" />
                  <input
                    type="file"
                    name="image"
                    accept="image/jpeg"
                    multiple={true}
                    onChange={onFilesPicked}
                    hidden
                  />
                </Button>
              </form>
            </>
          )}
        </Stack>
      )}
    </>
  );
};

export default UploadEmployeeVerificationImage;
