import { TabContext, TabList } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  Tab,
  Table,
  TableBody,
  TableContainer,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MainCard from "components/MainCard";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  Invoice,
  useCreateAndPromptPaymentIntentMutation,
  useGetCardReaderStatusQuery,
  useGetTransactionsQuery,
  useResetCardReaderMutation,
  useSyncPaymentIntentMutation,
  useTestOnlyPresentPaymentMethodMutation,
} from "service/api-slice";
import { IsLiveEnvironment } from "utils/env";
import { PaymentIntentRows } from "./PaymentIntents";
import { ReceiptsTabPanel } from "./ReceiptsTabPanel";
import { SubmitToReaderButton, useCardReader } from "components/payments/card-reader";
import { uniqBy } from "lodash";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 36,
  borderRadius: 5,
}));

const getErrorMessage = (error: any) => {
  if (!("data" in error)) {
    return "";
  }
  return (error.data as { message: string }).message;
};

const isAmountLargeEnough = (invoices: Invoice[]) => {
  return invoices.map((invoice) => invoice.total).reduce((total, x) => total + x, 0) >= 50;
};

export const PaymentDialog = ({
  invoiceId,
  txParams,
  open,
  handleClose,
}: {
  invoiceId: string;
  txParams: { vehicleId: string; startTime: Date; endTime: Date };
  open: boolean;
  handleClose: () => void;
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Tab related state
  const [selectedTab, setSelectedTab] = useState("receipt");
  const handleTabChanged = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  // Terminal reader related state
  const cardReader = useCardReader();
  const [processing, setProcessing] = useState(false);
  const [resetCardReader, resetCardReaderResult] = useResetCardReaderMutation();
  const { data: cardReaderStatus, error: cardReaderStatusError } = useGetCardReaderStatusQuery(
    cardReader.id,
    { pollingInterval: 1_000, skip: !processing }
  );

  // Payment related state
  const [createAndPromptPaymentIntent, createAndPromptPaymentIntentResult] =
    useCreateAndPromptPaymentIntentMutation();
  const [testOnlyPresentPaymentMethod, testOnlyPresentPaymentMethodResult] =
    useTestOnlyPresentPaymentMethodMutation();
  const [syncPaymentIntent, syncPaymentIntentResult] = useSyncPaymentIntentMutation();
  const [livePaymentIntent, setLivePaymentIntent] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [successfulPayment, setSuccessfulPayment] = useState(false);

  // Get all transactions for the vehicle `txParams.vehicleId`
  const { data: transactions, refetch: refetchTransactions } = useGetTransactionsQuery({
    start: txParams.startTime.toISOString(),
    end: txParams.endTime.toISOString(),
    page: 0,
    count: 500,
    vehicleId: txParams.vehicleId,
    expandInvoices: true,
  });

  // Get unique invoices for the transactions for that vehicle
  const invoices = useMemo(() => {
    const invoices = transactions?.data.map((txn) => txn.invoices || []).flat();
    return uniqBy(invoices, (invoice) => invoice.id);
  }, [transactions]);

  // Find the invoice corresponding to the transaction on this page
  const invoice = useMemo(() => {
    return invoices?.find((invoice) => invoice.id === invoiceId);
  }, [invoices, invoiceId]);

  // Find all unpaid invoices for the transactions for the vehicle
  const unpaidInvoices = useMemo(() => {
    return invoices?.filter((invoice) => invoice.status === "pending");
  }, [invoices]);

  // Get all payment intents for all invoices
  const getPaymentIntents = useCallback(() => {
    if (selectedTab === "receipt") {
      return invoice?.payment_intents || [];
    } else {
      return invoices?.map((invoice) => invoice.payment_intents).flat() || [];
    }
  }, [selectedTab, invoice, invoices]);

  const areInvoicesPaid = useCallback(() => {
    if (selectedTab === "receipt") {
      return invoice?.status === "paid";
    } else {
      return !unpaidInvoices || unpaidInvoices.length === 0;
    }
  }, [invoice, unpaidInvoices, selectedTab]);

  // If PI was created and sent to reader successfully, set processing to true and refetch
  // the transactions and invoices from the DB. Otherwise, display an error.
  useEffect(() => {
    if (createAndPromptPaymentIntentResult.isSuccess) {
      if (
        createAndPromptPaymentIntentResult.data.data.action &&
        createAndPromptPaymentIntentResult.data.data.action.status !== "failed"
      ) {
        refetchTransactions();
        setProcessing(true);
        if (createAndPromptPaymentIntentResult.data.data.payment_intent) {
          setLivePaymentIntent(
            createAndPromptPaymentIntentResult.data.data.payment_intent.stripe_id
          );
        }
        setPaymentError("");
        createAndPromptPaymentIntentResult.reset();
      } else {
        refetchTransactions();
        setProcessing(false);
        setPaymentError(
          createAndPromptPaymentIntentResult.data.data.action?.failure_message ?? "Unknown error"
        );
        createAndPromptPaymentIntentResult.reset();
      }
    } else if (createAndPromptPaymentIntentResult.isError) {
      refetchTransactions();
      setProcessing(false);
      setPaymentError(getErrorMessage(createAndPromptPaymentIntentResult.error));
      createAndPromptPaymentIntentResult.reset();
    }
  }, [createAndPromptPaymentIntentResult, refetchTransactions, setProcessing, setPaymentError]);

  // If the card reader was reset successfully, set processing to false.
  useEffect(() => {
    if (resetCardReaderResult.isSuccess) {
      refetchTransactions();
      setProcessing(false);
      setPaymentError("");
      resetCardReaderResult.reset();
    } else if (resetCardReaderResult.isError) {
      refetchTransactions();
      setPaymentError(getErrorMessage(resetCardReaderResult.error));
      resetCardReaderResult.reset();
    }
  }, [resetCardReaderResult, refetchTransactions, setProcessing, setPaymentError]);

  // If processing is true, poll the payment intent.
  useEffect(() => {
    if (processing && livePaymentIntent) {
      const interval = setInterval(() => {
        syncPaymentIntent(livePaymentIntent);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [processing, livePaymentIntent, syncPaymentIntent]);

  // If SyncPaymentIntent shows the PI succeeded, update the UI accordingly.
  useEffect(() => {
    if (syncPaymentIntentResult.isSuccess) {
      if (syncPaymentIntentResult.data.data.status === "succeeded") {
        refetchTransactions();
        setProcessing(false);
        setPaymentError("");
        setSuccessfulPayment(true);
      }
    } else if (syncPaymentIntentResult.isError) {
      setPaymentError(getErrorMessage(syncPaymentIntentResult.error));
    }
  }, [
    syncPaymentIntentResult,
    refetchTransactions,
    setProcessing,
    setPaymentError,
    setSuccessfulPayment,
  ]);

  // If terminal reader action failed (not enough funds, invalid, etc), update UI accordingly.
  useEffect(() => {
    if (cardReaderStatusError) {
      setPaymentError(getErrorMessage(cardReaderStatusError));
    }
    if (cardReaderStatus?.data.action?.status === "failed") {
      refetchTransactions();
      setProcessing(false);
      setPaymentError(cardReaderStatus.data.action.failure_message);
    }
  }, [
    cardReaderStatus,
    cardReaderStatusError,
    refetchTransactions,
    setProcessing,
    setPaymentError,
  ]);

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle>
        <FormattedMessage id="accept-payment" />
        {unpaidInvoices && unpaidInvoices.length > 1 && (
          <Alert severity="warning">
            <FormattedMessage id="multiple-unpaid-receipts" />
          </Alert>
        )}
      </DialogTitle>
      <DialogContent>
        {invoice && invoices && unpaidInvoices && (
          <Stack spacing={2}>
            <TabContext value={selectedTab}>
              <TabList onChange={handleTabChanged} aria-label="Receipts">
                <Tab
                  value="receipt"
                  key="receipt"
                  label={<FormattedMessage id="receipt" />}
                  disabled={processing}
                />
                {invoices.length > 1 && (
                  <Tab
                    value="all-receipts"
                    key="all-receipts"
                    label={<FormattedMessage id="all-receipts" />}
                    disabled={processing}
                  />
                )}
              </TabList>
              {transactions?.data && (
                <>
                  <ReceiptsTabPanel
                    tabName="receipt"
                    transactions={transactions?.data}
                    invoices={[invoice]}
                  />
                  {invoices.length > 1 && (
                    <ReceiptsTabPanel
                      tableSize="small"
                      tabName="all-receipts"
                      transactions={transactions?.data}
                      invoices={invoices}
                    />
                  )}
                </>
              )}
            </TabContext>
            <MainCard title={<FormattedMessage id="payments" />} content={false}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <PaymentIntentRows intents={getPaymentIntents()} />
                  </TableBody>
                </Table>
              </TableContainer>
            </MainCard>
            {paymentError && (
              <Alert
                severity="error"
                action={
                  <Button
                    sx={{ alignSelf: "center", paddingLeft: 10, paddingRight: 10 }}
                    variant="contained"
                    color="error"
                    onClick={() => resetCardReader(cardReader.id)}
                  >
                    <FormattedMessage id="reset-reader" />
                  </Button>
                }
              >
                <AlertTitle>
                  <strong>
                    <FormattedMessage id="payment-failed" />
                  </strong>
                </AlertTitle>
                {paymentError}
              </Alert>
            )}
            {successfulPayment && (
              <Alert severity="success">
                <AlertTitle>
                  <strong>
                    <FormattedMessage id="payment-succeeded" />
                  </strong>
                </AlertTitle>
              </Alert>
            )}
            {processing && (
              <Box sx={{ flexGrow: 1 }}>
                <BorderLinearProgress />
              </Box>
            )}
            {!processing && !areInvoicesPaid() && (
              <SubmitToReaderButton
                disabled={createAndPromptPaymentIntentResult.isLoading || processing}
                onClick={(readerId: string) => {
                  const selectedInvoices = selectedTab === "receipt" ? [invoice] : unpaidInvoices;
                  if (isAmountLargeEnough(selectedInvoices)) {
                    createAndPromptPaymentIntent({
                      invoiceIds: selectedInvoices.map((invoice) => invoice.id),
                      readerId: readerId,
                    });
                    setPaymentError("");
                  } else {
                    setPaymentError("Amount is too small; can't charge for less than $0.50.");
                  }
                }}
                cardReader={cardReader}
              />
            )}
            {processing && (
              <Button
                variant="outlined"
                disabled={resetCardReaderResult.isLoading}
                onClick={(e: SyntheticEvent) => {
                  e.preventDefault();
                  resetCardReader(cardReader.id);
                  setPaymentError("");
                }}
              >
                <FormattedMessage id="cancel-payment" />
              </Button>
            )}
            {!IsLiveEnvironment() && processing && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={testOnlyPresentPaymentMethodResult.isLoading}
                  onClick={() => testOnlyPresentPaymentMethod("acceptCharge")}
                >
                  <FormattedMessage id="simulate-payment" />
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={testOnlyPresentPaymentMethodResult.isLoading}
                  onClick={() => testOnlyPresentPaymentMethod("declineCharge")}
                >
                  <FormattedMessage id="simulate-decline" />
                </Button>
              </>
            )}
            {!processing && (
              <Button variant="outlined" onClick={handleClose}>
                <FormattedMessage id="close" />
              </Button>
            )}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};
