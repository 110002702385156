import {
  Box,
  Button,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import TableNoResults from "components/TableNoResults";
import { TablePagination } from "components/pagination/table-pagination";
import { endOfDay, formatDuration, intervalToDuration, parseISO, startOfDay } from "date-fns";
import VehicleName from "modules/site-operator/charging-stations/components/VehicleName";
import { PaymentDialog } from "modules/site-operator/transactions/components/PaymentDialog";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { formatValue } from "react-currency-input-field";
import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { Invoice, Transaction, useGetTransactionsQuery } from "service/api-slice";
import { useModuleContext } from "types/context";
import { Module } from "types/modules";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";
import TransactionVehicleInfo from "./TransactionVehicleInfo";

interface TransactionAndDialog {
  transaction: Transaction;
  openPaymentDialog: (transaction: Transaction) => void;
}

const columnHelper = createColumnHelper<TransactionAndDialog>();

const columns: any = [
  columnHelper.accessor("transaction.charging_station", {
    id: "charging_station",
    cell: (i) => i.getValue().name,
    header: () => <FormattedMessage id="charging-station" />,
  }),
  columnHelper.accessor("transaction.vehicle", {
    id: "vehicle",
    cell: (i) => <VehicleName vehicle={i.getValue()} />,
    header: () => <FormattedMessage id="vehicle" />,
  }),
  columnHelper.accessor("transaction.start_time", {
    id: "start_time",
    cell: (i) => parseAndFormatDate(i.getValue()),
    header: () => <FormattedMessage id="start_time" />,
  }),
  columnHelper.accessor("transaction.end_time", {
    id: "end_time",
    cell: (i) => (i.getValue() ? parseAndFormatDate(i.getValue()) : "-"),
    header: () => <FormattedMessage id="end_time" />,
  }),
  columnHelper.display({
    id: "total_power",
    cell: (i) =>
      i.row.original.transaction.meter_end == null
        ? "-"
        : `${
            (i.row.original.transaction.meter_end - i.row.original.transaction.meter_start) / 1000.0
          } kWh`,
    header: () => <FormattedMessage id="total_power" />,
  }),
  columnHelper.display({
    id: "total_cost",
    cell: (i) => {
      const invoice = i.row.original.transaction.invoices?.[0];
      const total = invoice ? invoice.total / 100 : 0;
      const status = invoice?.status === "paid" ? "success" : "default";
      return (
        <Chip
          color={status}
          size="small"
          label={formatValue({
            value: total.toString(),
            decimalSeparator: ".",
            decimalScale: 2,
            prefix: "$",
          })}
        />
      );
    },
    header: () => <FormattedMessage id="total" />,
  }),
  columnHelper.display({
    id: "total_collected",
    cell: (i) => {
      if (!i.row.original.transaction.invoices?.[0].collected) {
        return "-";
      }
      const collected = i.row.original.transaction.invoices[0].collected / 100;
      return formatValue({
        value: collected.toString(),
        decimalSeparator: ".",
        decimalScale: 2,
        prefix: "$",
      });
    },
    header: () => <FormattedMessage id="collected" />,
  }),
  columnHelper.accessor("transaction.end_reason", {
    id: "end_reason",
    cell: (i) => i.getValue()?.toString() ?? "-",
    header: () => <FormattedMessage id="end_reason" />,
  }),
  columnHelper.accessor("transaction.vendor_stopped_reason", {
    id: "vendor_stopped_reason_code",
    cell: (i) => i.getValue()?.code ?? "-",
    header: () => <FormattedMessage id="vendor_stopped_reason_code" />,
  }),
  columnHelper.accessor("transaction.vendor_stopped_reason", {
    id: "vendor_stopped_reason_name",
    cell: (i) => i.getValue()?.name ?? "-",
    header: () => <FormattedMessage id="vendor_stopped_reason_name" />,
  }),
];

const mobileColumns = [
  columnHelper.display({
    id: "transaction",
    cell: (i) => (
      <TransactionItemMobile
        transaction={i.row.original.transaction}
        openPaymentDialog={i.row.original.openPaymentDialog}
      />
    ),
  }),
];

const TransactionItemMobile = ({
  transaction,
  openPaymentDialog,
}: {
  transaction: Transaction;
  openPaymentDialog: (transaction: Transaction) => void;
}) => {
  let invoice: Invoice | null = null;
  if (transaction.invoices && transaction.invoices.length > 0) {
    invoice = transaction.invoices[0];
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Typography variant="h5">{transaction.charging_station.name}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Chip label={transaction.end_reason} size="small" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TransactionVehicleInfo transaction={transaction} />
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1">
          {transaction.meter_end == null
            ? "-"
            : `${(transaction.meter_end - transaction.meter_start) / 1000.0} kWh`}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        {invoice ? (
          <Typography variant="body1">${(invoice.total / 100).toFixed(2)}</Typography>
        ) : (
          <Typography variant="body1">-</Typography>
        )}
      </Grid>
      <Grid item xs={5}>
        <Box display={"flex"} justifyContent={"flex-end"}>
          {invoice && (
            <>
              {invoice.status === "paid" ? (
                <Chip label={invoice.status} size="small" color="success" />
              ) : (
                <Button
                  variant="outlined"
                  size="small"
                  disabled={!invoice}
                  onClick={(e: SyntheticEvent) => {
                    e.stopPropagation();
                    openPaymentDialog(transaction);
                  }}
                >
                  <FormattedMessage id="accept-payment" />
                </Button>
              )}
            </>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          <em>
            {formatDuration(
              intervalToDuration({
                start: parseISO(transaction.start_time),
                end: parseISO(transaction.end_time),
              }),
              { format: ["hours", "minutes"] }
            )}
          </em>
        </Typography>
        <Typography variant="body1">
          {parseAndFormatDate(transaction.start_time, { format: "MM/dd/yyyy HH:mm:ss" })}-{" "}
          {transaction.end_time
            ? parseAndFormatDate(transaction.end_time, { format: "HH:mm:ss zzz" })
            : "-"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const TransactionsTable = (props: {
  chargingStationId?: string;
  authorizationId?: string;
  vehicleId?: string;
  makeId?: string;
  modelId?: string;
  startTime: Date;
  endTime: Date;
  pageSize?: number;
  siteId?: string;
  orgId?: string | null;
  vpicId?: string;
  onClick: (transaction: Transaction) => void;
  transactions?: Transaction[];
  showFailureCodes?: boolean;
}) => {
  const theme = useTheme();
  const { module } = useModuleContext();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [transaction, setTransaction] = useState<Transaction | null>(null);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: props.pageSize ?? 25,
  });

  const pagination = useMemo(() => {
    return {
      pageIndex,
      pageSize,
    };
  }, [pageIndex, pageSize]);

  const invoice = useMemo(() => {
    if (transaction?.invoices && transaction.invoices.length > 0) {
      return transaction.invoices[0];
    }
  }, [transaction]);

  const args = useMemo(() => {
    return {
      start: props.startTime.toISOString(),
      end: props.endTime.toISOString(),
      siteId: props.siteId,
      orgId: props.orgId,
      page: pageIndex,
      count: pageSize,
      csId: props.chargingStationId,
      authorizationId: props.authorizationId,
      vehicleId: props.vehicleId,
      makeId: props.makeId,
      modelId: props.modelId,
      vpicId: props.vpicId,
      expandVehicle: true,
      expandInvoices: true,
      module: module,
    };
  }, [pageSize, pageIndex, props, module]);

  const { data, isError, isLoading } = useGetTransactionsQuery(args, {
    pollingInterval: 15_000,
    skipPollingIfUnfocused: true,
    skip: props.transactions !== undefined,
  });

  const pageCount = useMemo(() => {
    if (props.transactions) {
      return Math.ceil(props.transactions.length / pageSize);
    }
    return data?.meta.pagination.page_count ?? -1;
  }, [props.transactions, pageSize, data]);

  const tableTxns = useMemo(() => {
    if (props.transactions) {
      return props.transactions.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
    }
    return data?.data ?? [];
  }, [props.transactions, data, pageSize, pageIndex]);

  const table = useReactTable({
    data:
      tableTxns.map((txn) => {
        return { transaction: txn, openPaymentDialog: showPaymentDialog };
      }) ?? [],
    columns: isSmallScreen ? mobileColumns : columns,
    pageCount: pageCount,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (props.chargingStationId && !isSmallScreen) {
      table.getColumn("charging_station")?.toggleVisibility(false);
    }
    if (module === Module.FleetOperator) {
      table.getColumn("charging_station")?.toggleVisibility(false);
      table.getColumn("total_cost")?.toggleVisibility(false);
      table.getColumn("end_reason")?.toggleVisibility(false);
    }
    if (!props.showFailureCodes && !isSmallScreen) {
      table.getColumn("vendor_stopped_reason_code")?.toggleVisibility(false);
      table.getColumn("vendor_stopped_reason_name")?.toggleVisibility(false);
    }
  }, [props.chargingStationId, table, isSmallScreen, module, props.showFailureCodes]);

  const [openPaymentDialog, setOpenPaymentDialog] = useState<boolean>(false);

  function showPaymentDialog(transaction: Transaction) {
    setTransaction(transaction);
    setOpenPaymentDialog(true);
  }

  function closeAcceptPayment() {
    setOpenPaymentDialog(false);
    setTransaction(null);
  }

  return (
    <>
      {invoice && transaction?.vehicle && module === Module.SiteOperator && (
        <PaymentDialog
          invoiceId={invoice.id}
          txParams={{
            startTime: startOfDay(parseISO(transaction.start_time)),
            endTime: endOfDay(parseISO(transaction.end_time)),
            vehicleId: transaction.vehicle!.id,
          }}
          open={openPaymentDialog}
          handleClose={closeAcceptPayment}
        />
      )}
      <TableContainer>
        <Table sx={{ minWidth: 350 }} aria-label="Charger Sessions Table">
          {!isSmallScreen && (
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => {
                return (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableCell key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableHead>
          )}
          <TableBody>
            {(isLoading || isError) &&
              table.getHeaderGroups().map((headerGroup) => {
                return [...Array(10).keys()].map((i) => (
                  <TableRow key={i}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableCell key={`${i}-${header.id}`} colSpan={header.colSpan}>
                          <Skeleton />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ));
              })}
            {table.getRowModel().rows.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  hover={true}
                  sx={{ cursor: "pointer" }}
                  onClick={() => props.onClick(row.original.transaction)}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {table.getRowModel().rows.length === 0 && (
              <TableNoResults columnCount={table.getAllColumns().length} />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell sx={{ p: 2 }} colSpan={table.getAllLeafColumns().length}>
                <TablePagination
                  gotoPage={table.setPageIndex}
                  totalPages={table.getPageCount()}
                  setPageSize={table.setPageSize}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
