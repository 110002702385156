import { Alert, Box, Button, Paper, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import axios from "axios";
import { Feature } from "experimentation/Feature";
import { Flags } from "experimentation/Flags";
import { PumpOperationResponse, PumpState, PumpStation } from "modules/site-operator/pumps/records";
import { SortOrder } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { openSnackbar } from "store/reducers/snackbar";
import { sec } from "utils/security";
import { ViewProcessor } from "./pump-processor";
import { useFeatureFlags } from "experimentation/FeatureFlagProvider";

/* eslint react/no-unstable-nested-components : 0 */

const Pumps: React.FC = () => {
  const { state } = useLocation();
  const exp = useFeatureFlags();

  const [pumpStation, setPumpStation] = useState<PumpStation>(state.pump_station);
  const dispatch = useDispatch();

  const BaseUrl = () => {
    if (exp.evaluateFeature(Flags.CoolingV2)) {
      return `${process.env.REACT_APP_API_BASE_URL}/pump-stations2/`;
    } else {
      return `${process.env.REACT_APP_API_BASE_URL}/pump-stations/`;
    }
  };

  const PowerOffPumpStation = () => {
    async function powerOff() {
      const token = await sec.getAccessTokenSilently();
      await axios.post<PumpStation>(
        BaseUrl() + `${pumpStation.id}/power-off`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTimeout(() => {
        SyncPumpStation();
      }, 1000);
    }
    powerOff();
  };

  const PowerOnPumpStation = () => {
    async function powerOn() {
      const token = await sec.getAccessTokenSilently();
      await axios.post<PumpStation>(
        BaseUrl() + `${pumpStation.id}/power-on`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTimeout(() => {
        SyncPumpStation();
      }, 1000);
    }
    powerOn();
  };

  const RefreshPumpStation = () => {
    async function refresh() {
      const token = await sec.getAccessTokenSilently();
      await axios.post<PumpOperationResponse>(
        BaseUrl() + `${pumpStation.id}/enable-refresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTimeout(() => {
        SyncPumpStation();
      }, 1000);
    }
    refresh();
  };

  const FreezePumpStation = () => {
    async function freeze() {
      const token = await sec.getAccessTokenSilently();
      await axios.post<PumpOperationResponse>(
        BaseUrl() + `${pumpStation.id}/disable-refresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTimeout(() => {
        SyncPumpStation();
      }, 1000);
    }
    freeze();
  };

  const SyncPumpStation = () => {
    async function sync() {
      const token = await sec.getAccessTokenSilently();
      const response = await axios.post<PumpStation>(
        BaseUrl() + `${pumpStation.id}/sync`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPumpStation(response.data);
    }
    sync();
  };

  const StartPump = (id: string) => {
    async function start() {
      const token = await sec.getAccessTokenSilently();

      const pump = pumpStation.pump_states.find((x: PumpState) => x.pump_id === id);

      if (pump !== undefined && pump.disabled === true) {
        await axios.post<PumpState>(
          BaseUrl() + `${pumpStation.id}/enable-pump/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setTimeout(() => {
          SyncPumpStation();
        }, 1000);
      }

      if (pump !== undefined && pump.running !== true) {
        await axios.post<PumpState>(
          BaseUrl() + `${pumpStation.id}/start-pump/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setTimeout(() => {
          SyncPumpStation();
        }, 1000);
      }
    }
    start();
  };

  const StopPump = (id: string) => {
    async function stop() {
      const token = await sec.getAccessTokenSilently();
      await axios.post<PumpState>(
        BaseUrl() + `${pumpStation.id}/stop-pump/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTimeout(() => {
        SyncPumpStation();
      }, 1000);
    }
    stop();
  };

  const EnablePump = (id: string) => {
    async function enable() {
      const token = await sec.getAccessTokenSilently();
      await axios.post<PumpOperationResponse>(
        BaseUrl() + `${pumpStation.id}/enable-pump/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTimeout(() => {
        SyncPumpStation();
      }, 1000);
    }
    enable();
    openSnackbar({
      message: "Enabling pump...",
      status: "info",
      autoHideDuration: 2000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  };

  const DisablePump = (id: string) => {
    async function disable() {
      const token = await sec.getAccessTokenSilently();
      await axios.post<PumpOperationResponse>(
        BaseUrl() + `${pumpStation.id}/disable-pump/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTimeout(() => {
        SyncPumpStation();
      }, 1000);
    }
    disable();
    dispatch(
      openSnackbar({
        message: "Enabling pump...",
        status: "info",
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      })
    );
  };

  const StartPumpButton = (id: string) => {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={(x) => StartPump(id)}
        disabled={!pumpStation.powered_on}
      >
        Run
      </Button>
    );
  };

  const StopPumpButton = (id: string) => {
    return (
      <Button variant="contained" onClick={(x) => StopPump(id)} disabled={!pumpStation.powered_on}>
        Stop
      </Button>
    );
  };

  const EnablePumpButton = (id: string) => {
    return (
      <Button
        variant="contained"
        onClick={(x) => EnablePump(id)}
        disabled={!pumpStation.powered_on}
      >
        Enable
      </Button>
    );
  };

  const DisablePumpButton = (id: string) => {
    return (
      <Button
        variant="contained"
        onClick={(x) => DisablePump(id)}
        disabled={!pumpStation.powered_on}
      >
        Disable
      </Button>
    );
  };
  return (
    <Grid2 container spacing={2}>
      <Feature flag={Flags.CoolingV2} match={false}>
        <Grid2 xs={12}>
          <Alert severity="warning">Using old v1 cooling API</Alert>
        </Grid2>
      </Feature>
      <Grid2 xs={6}>
        <Paper elevation={1}>
          <Stack spacing={1}>
            <Box>
              <Typography variant="h3" margin={2}>
                Pump Station
              </Typography>
              <Typography variant="body1" margin={2}>
                <b>ID:</b> {pumpStation.id}
              </Typography>
              <Typography variant="body1" margin={2}>
                <b>Name:</b> {pumpStation.name}
              </Typography>
              <Typography variant="body1" margin={2}>
                <b>Site:</b> {pumpStation.site_name}
              </Typography>
              <Typography variant="body1" margin={2}>
                <b>State:</b> {pumpStation.powered_on ? "On" : "Off"}
              </Typography>
              <Typography variant="body1" margin={2}>
                <b>Service Mode:</b> {pumpStation.service_mode ? "true" : "false"}
              </Typography>
              <Typography variant="body1" margin={2}>
                <b>Refresh Active:</b> {pumpStation.refresh_active ? "true" : "false"}
              </Typography>
              <Typography variant="body1" margin={2}>
                <b>Refresh Enabled:</b> {pumpStation.refresh_enabled ? "true" : "false"}
              </Typography>
              <Typography variant="body1" margin={2}>
                <b>Version:</b> {pumpStation.version}
              </Typography>
              <Typography variant="body1" margin={2}>
                <b>Last Updated:</b> {pumpStation.last_updated.toString()}
              </Typography>
              <Box margin={2}>
                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={pumpStation.powered_on}
                    onClick={PowerOnPumpStation}
                    title="Power On Pump Station"
                  >
                    Power On
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!pumpStation.powered_on}
                    title="Power on pump station"
                    onClick={PowerOffPumpStation}
                  >
                    Power Off
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    title="Enable refresh of pump station"
                    onClick={RefreshPumpStation}
                  >
                    Refresh
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    title="Disable refresh of pump station"
                    onClick={FreezePumpStation}
                  >
                    Freeze
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    title="Get the latest state of the pump station"
                    onClick={SyncPumpStation}
                  >
                    Sync
                  </Button>
                </Stack>
              </Box>
            </Box>
          </Stack>
        </Paper>
      </Grid2>
      {<ViewProcessor />}
      <Grid2 xs={12}>
        <DataTable value={pumpStation.pump_states} sortField="pump_id" sortOrder={SortOrder.ASC}>
          <Column
            header="Status"
            body={(x: PumpState) =>
              !x.disabled && x.running ? StopPumpButton(x.pump_id) : StartPumpButton(x.pump_id)
            }
          />
          <Column
            header="State"
            body={(x: PumpState) =>
              x.disabled ? EnablePumpButton(x.pump_id) : DisablePumpButton(x.pump_id)
            }
          />
          <Column header="Pump Id" field="pump_id" sortable filter filterField="pump_id" />
          <Column header="Running" field="running" sortable filter filterField="running" />
          <Column header="Disabled" field="disabled" sortable filter filterField="disabled" />
        </DataTable>
      </Grid2>
    </Grid2>
  );
};

export default Pumps;
