import { Badge, Chip } from "@mui/material";
import { ChargingStation } from "service/api-slice";

const ConnectedVehicleStatusChip = ({
  cs,
  size,
}: {
  cs: ChargingStation | undefined;
  size?: "small" | "medium";
}) => {
  if (!size) {
    size = "small";
  }

  let color: "info" | "error" | "warning" | "success" = "error";
  let variant: "outlined" | "filled" = "outlined";
  let label: string;

  switch (cs?.status?.status) {
    case undefined:
    case null:
      color = "error";
      label = "CS No Status";
      break;

    case "Available":
      color = "info";
      label = "CS " + cs.status.status;
      break;

    case "Reserved":
    case "Unavailable":
    case "Faulted":
    case "Offline":
    case "Unknown":
      color = "error";
      label = "CS " + cs.status.status;
      break;

    case "Occupied":
      if (!cs.status.mac_address) {
        color = "warning";
        label = "CS Occupied";
      } else {
        color = "success";
        variant = "filled";
        label = "MAC: " + cs.status.mac_address;
      }
      break;

    default:
      color = "error";
      label = "CS Unexpected status: " + cs!.status!.status;
  }

  let badgeContent: string | undefined;

  let chip = <Chip size={size} variant={variant} color={color} label={label} />;

  if (cs?.status?.errors && cs.status.errors.length > 0) {
    badgeContent = cs.status.errors.flatMap((e) => e.code).join(", ");
    chip = (
      <Badge color="error" badgeContent={badgeContent}>
        {chip}
      </Badge>
    );
  }

  return chip;
};

export default ConnectedVehicleStatusChip;
