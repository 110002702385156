import { WarningOutlined } from "@ant-design/icons";
import { Stack, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import MainCard from "components/MainCard";
import { FormattedMessage } from "react-intl";
import { VendorStopReason } from "service/api-slice";

const ErrorCodeCard = ({ error }: { error: VendorStopReason }) => {
  return (
    <MainCard
      title={
        <>
          <Stack direction="row" alignItems="center">
            {error.is_fault && (
              <>
                <WarningOutlined />
                &nbsp;
              </>
            )}
            <FormattedMessage id="stop-info" />
          </Stack>
        </>
      }
      sx={{ mt: 2 }}
      content={false}
    >
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow hover={false} key="stop-reason-code">
              <TableCell>
                <FormattedMessage id="stop-reason-code" />
              </TableCell>
              <TableCell>{error.code}</TableCell>
            </TableRow>
            <TableRow hover={false} key="stop-reason-name">
              <TableCell>
                <FormattedMessage id="stop-reason-name" />
              </TableCell>
              <TableCell>{error.name}</TableCell>
            </TableRow>
            <TableRow hover={false} key="stop-reason-common-causes">
              <TableCell>
                <FormattedMessage id="stop-reason-common-causes" />
              </TableCell>
              <TableCell>
                {error.common_causes.map((cause, i) => (
                  <span key={`cause-${cause}`}>
                    {cause} <br />
                  </span>
                ))}
              </TableCell>
            </TableRow>
            <TableRow hover={false} key="stop-reason-instructions">
              <TableCell>
                <FormattedMessage id="stop-reason-instructions" />
              </TableCell>
              <TableCell>
                {error.instructions.map((instruction, i) => (
                  <span key={`instruction-${instruction}`}>
                    {instruction} <br />
                  </span>
                ))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default ErrorCodeCard;
