import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Outlet } from "react-router";
import { Module } from "types/modules";

const UtilityOperatorIndex = () => {
  const context = useMemo(() => {
    return {
      module: Module.UtilityOperator,
    };
  }, []);

  return (
    <Stack rowGap={2}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h4">
            <FormattedMessage id="overview" />
          </Typography>
        </Grid>
      </Grid>
      <Outlet context={context} />
    </Stack>
  );
};

export default UtilityOperatorIndex;
