export const Module = {
  SiteOperator: "site-operator",
  FleetOperator: "fleet-operator",
  UtilityOperator: "utility-operator",
  PowerManagement: "power-management",
  Admin: "admin",
  Playground: "playground",
} as const;

export type ModuleType = typeof Module[keyof typeof Module];
