import DefaultDashboard from "modules/utility-operator/dashboards/default";
import { RouteObject } from "react-router";
import { ModuleGuard } from "utils/route-guard/ModuleGuard";
import UtilityOperatorIndex from "./UtilityOperatorIndex";

export const UtilityOperatorRoutes: RouteObject = {
  element: <ModuleGuard modules={["utility-operator"]} />,
  path: "utility-operator",
  children: [
    {
      element: <UtilityOperatorIndex />,
      children: [
        {
          index: true,
          element: <DefaultDashboard />,
        },
      ],
    },
  ],
};
