import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { ChangeEvent, Fragment, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  ChargingStation,
  useCancelChargingStationPreauthMutation,
  useFleetAuthorizeChargingStationMutation,
  useGetChargingStationsQuery,
  useManualAuthorizeChargingStationMutation,
  usePreauthChargingStationMutation,
  useSetDisableBindToMacAddressMutation,
  useSetOverrideMinValidUntilMutation,
  useMoveAuthorizationMutation,
} from "service/api-slice";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { SubmitToReaderButton, useCardReader } from "components/payments/card-reader";
import {
  PaymentStatus,
  TestOnlyPresentPaymentButtons,
  usePaymentIntentSyncer,
} from "components/payments/payment-intent";
import CurrencyInput from "react-currency-input-field";
import { openSnackbarError } from "store/reducers/snackbar";
import { useDispatch } from "react-redux";
import { CurrencyInputOnChangeValues } from "react-currency-input-field";
import { parseAndFormatDate } from "utils/datetime/parseAndFormatDate";
import StartTransactionButton from "modules/site-operator/charging-stations/components/StartTransactionButton";
import isValidEmail from "utils/email";
import FleetSelector from "components/FleetSelector";
import AuthorizationStatusChip from "./AuthorizationStatusChip";
import ConnectedVehicleStatusChip from "components/ConnectedVehicleStatusChip";
import { useFeatureFlags } from "experimentation/FeatureFlagProvider";
import { Flags } from "experimentation/Flags";
import { addMinutes, formatRFC3339 } from "date-fns";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useModuleContext } from "types/context";
import { useNavigateWithModule } from "hooks/useNavigateWithModule";
import { formatCents } from "utils/currency";

const socSliderProps = {
  step: 10,
  min: 50,
  max: 100,
  marks: [
    { value: 50, label: "50%" },
    { value: 60 },
    { value: 70 },
    { value: 80, label: "80%" },
    { value: 90 },
    { value: 100, label: "100%" },
  ],
};

const PreauthorizeCreditCardForm = ({
  cs,
  refetch,
}: {
  cs: ChargingStation;
  refetch: () => void;
}) => {
  const cardReader = useCardReader();
  const piSyncer = usePaymentIntentSyncer("requires_capture", refetch);

  const [amount, setAmount] = useState<number>(40);
  const [soc, setSOC] = useState<number>(100);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);

  const [preauthChargingStation, preauthChargingStationResult] =
    usePreauthChargingStationMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (preauthChargingStationResult.isSuccess) {
      piSyncer.setPaymentIntent(preauthChargingStationResult.data.data.payment_intent);
      cardReader.startPaymentIntent(preauthChargingStationResult.data.data.payment_intent);
      preauthChargingStationResult.reset();
      refetch();
    } else if (preauthChargingStationResult.isError) {
      cardReader.setFailure(
        "Failed to preauthorize",
        JSON.stringify(preauthChargingStationResult.error)
      );
      preauthChargingStationResult.reset();
      refetch();
    }
  }, [cardReader, preauthChargingStationResult, piSyncer, refetch]);

  const onPreauthClick = useCallback(
    (readerId: string) => {
      if (amount < 1) {
        dispatch(
          openSnackbarError({
            title: "Failed to preauthorize",
            message: "Amount must be at least $1.00",
          })
        );
        return;
      }
      preauthChargingStation({
        csId: cs.id,
        amount_in_cents: amount * 100,
        state_of_charge: soc,

        email_receipt_to: email === "" ? undefined : email,
        reader_id: readerId,
      });
      cardReader.startOp();
    },
    [amount, cardReader, cs, dispatch, email, preauthChargingStation, soc]
  );

  const onButtonAmountChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newAmount: number) => {
      setAmount(newAmount);
    },
    [setAmount]
  );

  const onAmountChange = useCallback(
    (value?: string, name?: string, values?: CurrencyInputOnChangeValues) => {
      if (values?.float) {
        setAmount(values.float);
      } else {
        setAmount(0);
      }
    },
    [setAmount]
  );

  const onSOCSliderChange = useCallback(
    (_, value) => {
      setSOC(value as number);
    },
    [setSOC]
  );

  const onEmailChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const email = e.target.value;
      setEmailError(email !== "" && !isValidEmail(email));
      setEmail(email);
    },
    [setEmail, setEmailError]
  );

  const [cancel, cancelResult] = useCancelChargingStationPreauthMutation();
  useEffect(() => {
    if (cancelResult.isSuccess || cancelResult.isError) {
      refetch();
    }
  }, [cancelResult, refetch]);

  const onCancelClick = useCallback(() => {
    cardReader.reset();
    piSyncer.reset();
    cancel(cs.id);
  }, [cancel, cardReader, cs, piSyncer]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <InputLabel htmlFor="amount">
          <FormattedMessage id="amount" />
        </InputLabel>
      </Grid>
      <Grid container item xs={8}>
        <Grid item xs={12}>
          <CurrencyInput
            id="amount"
            prefix="$"
            decimalScale={2}
            allowDecimals={true}
            decimalSeparator="."
            disabled={!!piSyncer.paymentIntent}
            value={amount.toString()}
            onValueChange={onAmountChange}
          />
        </Grid>
        <Grid item xs={12}>
          <ToggleButtonGroup value={amount} onChange={onButtonAmountChange} exclusive>
            <ToggleButton value={20}> $20 </ToggleButton>
            <ToggleButton value={40}> $40 </ToggleButton>
            <ToggleButton value={80}> $80 </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <InputLabel htmlFor="soc">
          <FormattedMessage id="soc" />
        </InputLabel>
      </Grid>
      <Grid item xs={8}>
        <Slider
          {...socSliderProps}
          id="soc"
          disabled={!!piSyncer.paymentIntent}
          value={soc}
          onChange={onSOCSliderChange}
        />
      </Grid>
      <Grid item xs={4}>
        <InputLabel htmlFor="email">
          <FormattedMessage id="email" />
        </InputLabel>
      </Grid>
      <Grid item xs={8}>
        <TextField
          id="email"
          size="small"
          fullWidth
          disabled={!!piSyncer.paymentIntent}
          value={email}
          onChange={onEmailChange}
          error={emailError}
          {...(emailError ? { helperText: <FormattedMessage id="enter-valid-email" /> } : {})}
        />
      </Grid>
      <Grid item xs={12}>
        <SubmitToReaderButton
          disabled={
            preauthChargingStationResult.isLoading ||
            !!piSyncer.paymentIntent ||
            cardReader.isResetting ||
            emailError
          }
          onClick={onPreauthClick}
          cardReader={cardReader}
        />
      </Grid>
      {piSyncer.paymentIntent && (
        <Grid item xs={12}>
          <Button variant="outlined" onClick={onCancelClick}>
            <FormattedMessage id="cancel" />
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <PaymentStatus cardReader={cardReader} piSyncer={piSyncer} />
      </Grid>
      <Grid item xs={12}>
        <TestOnlyPresentPaymentButtons pi={piSyncer.paymentIntent} />
      </Grid>
    </Grid>
  );
};

const PreauthorizeFleetForm = ({ cs, refetch }: { cs: ChargingStation; refetch: () => void }) => {
  const [authorize, authorizeResult] = useFleetAuthorizeChargingStationMutation();
  const dispatch = useDispatch();

  const [fleetId, setFleetId] = useState<string>("");
  const [soc, setSOC] = useState<number>(100);

  const onSOCSliderChange = useCallback(
    (_, value) => {
      setSOC(value as number);
    },
    [setSOC]
  );
  const onAuthorizeClick = useCallback(() => {
    authorize({
      csId: cs.id,
      fleetId: fleetId,
      state_of_charge: soc,
    });
  }, [authorize, cs, fleetId, soc]);

  useEffect(() => {
    if (authorizeResult.isSuccess) {
      refetch();
    } else if (authorizeResult.isError) {
      dispatch(
        openSnackbarError({
          title: "Failed to Fleet Authorize",
          message: JSON.stringify(authorizeResult.error),
        })
      );
      authorizeResult.reset();
      refetch();
    }
  }, [dispatch, authorizeResult, refetch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <InputLabel htmlFor="fleet">
          <FormattedMessage id="select-fleet" />
        </InputLabel>
      </Grid>
      <Grid item xs={8}>
        <FleetSelector fleetId={fleetId} setFleetId={setFleetId} />
      </Grid>
      <Grid item xs={4}>
        <InputLabel htmlFor="soc">
          <FormattedMessage id="soc" />
        </InputLabel>
      </Grid>
      <Grid item xs={8}>
        <Slider {...socSliderProps} id="soc" value={soc} onChange={onSOCSliderChange} />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={onAuthorizeClick} disabled={!fleetId} variant="contained" fullWidth>
          <FormattedMessage id="authorize" />
        </Button>
      </Grid>
    </Grid>
  );
};

const ManualAuthorizeForm = ({ cs, refetch }: { cs: ChargingStation; refetch: () => void }) => {
  const [authorize, authorizeResult] = useManualAuthorizeChargingStationMutation();
  const dispatch = useDispatch();

  const [reason, setReason] = useState<string>("select");
  const [note, setNote] = useState<string>("");
  const [soc, setSOC] = useState<number>(100);

  const onReasonSelect = useCallback(
    (e: SelectChangeEvent<string>) => {
      setReason(e.target.value);
    },
    [setReason]
  );
  const onNoteChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setNote(e.target.value);
    },
    [setNote]
  );
  const onSOCSliderChange = useCallback(
    (_, value) => {
      setSOC(value as number);
    },
    [setSOC]
  );
  const onAuthorizeClick = useCallback(() => {
    let fullReason = reason;
    if (note) {
      fullReason += `
---
${note}`;
    }
    authorize({
      csId: cs.id,
      reason: fullReason,
      state_of_charge: soc,
    });
  }, [authorize, cs, reason, note, soc]);

  useEffect(() => {
    if (authorizeResult.isSuccess) {
      refetch();
    } else if (authorizeResult.isError) {
      dispatch(
        openSnackbarError({
          title: "Failed to Manual Authorize",
          message: JSON.stringify(authorizeResult.error),
        })
      );
      authorizeResult.reset();
      refetch();
    }
  }, [dispatch, authorizeResult, refetch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <InputLabel htmlFor="reason">
          <FormattedMessage id="reason" />
        </InputLabel>
      </Grid>
      <Grid item xs={8}>
        <Select id="reason" value={reason} onChange={onReasonSelect}>
          <MenuItem value="select">
            <FormattedMessage id="select-reason" />
          </MenuItem>
          <MenuItem value="Test">
            <FormattedMessage id="testing" />
          </MenuItem>
          <MenuItem value="Other">
            <FormattedMessage id="other" />
          </MenuItem>
        </Select>
      </Grid>
      <Grid item xs={4}>
        <InputLabel htmlFor="note">
          <FormattedMessage id="note" />
        </InputLabel>
      </Grid>
      <Grid item xs={8}>
        <TextField
          id="note"
          fullWidth
          value={note}
          onChange={onNoteChange}
          error={!note}
          {...(!note ? { helperText: <FormattedMessage id="note-required" /> } : {})}
        />
      </Grid>
      <Grid item xs={4}>
        <InputLabel htmlFor="soc">
          <FormattedMessage id="soc" />
        </InputLabel>
      </Grid>
      <Grid item xs={8}>
        <Slider {...socSliderProps} id="soc" value={soc} onChange={onSOCSliderChange} />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={onAuthorizeClick}
          disabled={reason === "select" || !note}
          variant="contained"
          fullWidth
        >
          <FormattedMessage id="authorize" />
        </Button>
      </Grid>
    </Grid>
  );
};

export const AuthorizeForm = (props: { cs: ChargingStation; refetch: () => void }) => {
  const exp = useFeatureFlags();

  const [selectedTab, setSelectedTab] = useState("credit-card");
  const onTabChanged = useCallback(
    (event: SyntheticEvent, newValue: string) => {
      setSelectedTab(newValue);
    },
    [setSelectedTab]
  );

  return (
    <TabContext value={selectedTab}>
      <TabList onChange={onTabChanged} variant="fullWidth" indicatorColor="secondary" centered>
        <Tab label="Credit Card" value="credit-card" />
        <Tab label="Fleet" value="fleet" />
        {exp.evaluateFeature(Flags.ManualAuthorization) && <Tab label="Manual" value="manual" />}
      </TabList>
      <TabPanel value="legacy">
        <Typography variant="h1">Legacy Mode</Typography>
      </TabPanel>
      <TabPanel value="credit-card">
        <PreauthorizeCreditCardForm {...props} />
      </TabPanel>
      <TabPanel value="fleet">
        <PreauthorizeFleetForm {...props} />
      </TabPanel>
      <TabPanel value="manual">
        <ManualAuthorizeForm {...props} />
      </TabPanel>
    </TabContext>
  );
};

export const PreauthorizeStatusForm = ({
  cs,
  refetch,
}: {
  cs: ChargingStation;
  refetch: () => void;
}) => {
  const dispatch = useDispatch();

  const [cancel, cancelResult] = useCancelChargingStationPreauthMutation();
  useEffect(() => {
    if (cancelResult.isSuccess) {
      refetch();
    } else if (cancelResult.isError) {
      dispatch(
        openSnackbarError({
          title: "Failed to Cancel Preauth",
          message: JSON.stringify(cancelResult.error),
        })
      );
      refetch();
    }
  }, [cancelResult, dispatch, refetch]);
  const onClickCancel = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      cancel(cs.id);
    },
    [cancel, cs]
  );

  const [showExtendedControls, setShowExtendedControls] = useState(false);

  const [overrideValidUntil, overrideValidUntilResult] = useSetOverrideMinValidUntilMutation();
  useEffect(() => {
    if (overrideValidUntilResult.isSuccess) {
      refetch();
    } else if (overrideValidUntilResult.isError) {
      dispatch(
        openSnackbarError({
          title: "Failed to Extend Authorization",
          message: JSON.stringify(overrideValidUntilResult.error),
        })
      );
      refetch();
    }
  }, [dispatch, overrideValidUntilResult, refetch]);
  const onClickExtend = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      overrideValidUntil({ csId: cs.id, time: formatRFC3339(addMinutes(new Date(), 10)) });
    },
    [overrideValidUntil, cs]
  );

  const [setDisableBindToMac, setDisableBindToMacResult] = useSetDisableBindToMacAddressMutation();
  useEffect(() => {
    if (setDisableBindToMacResult.isSuccess) {
      refetch();
    } else if (setDisableBindToMacResult.isError) {
      dispatch(
        openSnackbarError({
          title: "Failed to Disable BindToMAC",
          message: JSON.stringify(setDisableBindToMacResult.error),
        })
      );
      refetch();
    }
  }, [dispatch, setDisableBindToMacResult, refetch]);
  const onPreauthEnabledChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDisableBindToMac({ csId: cs.id, disable: e.target.checked });
    },
    [setDisableBindToMac, cs]
  );

  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const onCloseMoveDialog = useCallback(() => {
    setShowMoveDialog(false);
  }, [setShowMoveDialog]);
  const getChargingStations = useGetChargingStationsQuery(
    { expand_authorization: true },
    { skip: !showMoveDialog }
  );

  const [destinationCsId, setDestinationCsId] = useState<string>("");
  const onDestinationChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      setDestinationCsId(e.target.value);
    },
    [setDestinationCsId]
  );
  const [moveAuth, moveAuthResult] = useMoveAuthorizationMutation();
  const onMoveClicked = useCallback(() => {
    moveAuth({ sourceCsId: cs.id, authorizationId: cs.authorization!.id, destinationCsId });
    setShowMoveDialog(false);
  }, [moveAuth, cs, destinationCsId]);

  const { module } = useModuleContext();
  const navigate = useNavigateWithModule(module);
  useEffect(() => {
    if (moveAuthResult.isSuccess) {
      navigate("charging-stations/" + destinationCsId);
    }
    if (moveAuthResult.isError) {
      refetch();
      dispatch(
        openSnackbarError({
          title: "Failed to Move Authorization",
          message: JSON.stringify(moveAuthResult.error),
        })
      );
    }
  }, [destinationCsId, dispatch, moveAuthResult, navigate, refetch]);

  const authorization = getAuthorization(cs);
  if (!authorization) {
    return <Typography>No Preauthorization</Typography>;
  }
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {authorization.status === "Authorized" &&
            authorization.mac_address !== "" &&
            cs.status?.status === "Occupied" &&
            cs.current_transaction === null && (
              <TableRow>
                <TableCell colSpan={2}>
                  <StartTransactionButton chargingStationId={cs.id} />
                </TableCell>
              </TableRow>
            )}
          <TableRow>
            <TableCell>
              <FormattedMessage id="preauth-type" />
            </TableCell>
            <TableCell>{authorization.type}</TableCell>
          </TableRow>
          {authorization?.mac_address && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="mac-address" />
              </TableCell>
              <TableCell>{authorization?.mac_address}</TableCell>
            </TableRow>
          )}
          {authorization?.stop_conditions?.state_of_charge && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="ending-soc" />
              </TableCell>
              <TableCell>
                {!cs.current_transaction &&
                  authorization?.stop_conditions?.ending_state_of_charge && (
                    <Fragment>{authorization?.stop_conditions?.ending_state_of_charge}% /</Fragment>
                  )}
                {authorization?.stop_conditions?.state_of_charge}%
              </TableCell>
            </TableRow>
          )}
          {authorization?.stop_conditions?.cost_in_cents && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="amount" />
              </TableCell>
              <TableCell>
                {!cs.current_transaction &&
                  authorization?.stop_conditions?.ending_cost_in_cents && (
                    <Fragment>
                      {formatCents(authorization?.stop_conditions?.ending_cost_in_cents)} /
                    </Fragment>
                  )}
                {formatCents(authorization?.stop_conditions?.cost_in_cents)}
                {authorization?.stop_conditions?.previous_cost_in_cents && (
                  <FormattedMessage
                    id="val-from-previous-cs"
                    values={{
                      val: formatCents(authorization?.stop_conditions?.previous_cost_in_cents),
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          )}
          {authorization?.stop_conditions?.energy_wh && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="energy-delivered" />
              </TableCell>
              <TableCell>{authorization?.stop_conditions?.energy_wh} Wh</TableCell>
            </TableRow>
          )}
          {authorization?.valid_until && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="valid-until" />
              </TableCell>
              <TableCell>{parseAndFormatDate(authorization?.valid_until)}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={2}>
              <LoadingButton
                size="small"
                loading={cancelResult.isLoading}
                variant="outlined"
                color="error"
                onClick={onClickCancel}
              >
                <FormattedMessage id="end-authorization" />
              </LoadingButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>
              <ToggleButton
                value="show-extended"
                selected={showExtendedControls}
                onClick={() => setShowExtendedControls(!showExtendedControls)}
                fullWidth
              >
                {showExtendedControls ? <UpOutlined /> : <DownOutlined />}
              </ToggleButton>
            </TableCell>
          </TableRow>
          {showExtendedControls && (
            <Fragment>
              <TableRow>
                <TableCell colSpan={2}>
                  <LoadingButton
                    size="small"
                    loading={overrideValidUntilResult.isLoading}
                    variant="outlined"
                    color="secondary"
                    onClick={onClickExtend}
                  >
                    <FormattedMessage id="extend-authorization" />
                  </LoadingButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!authorization.disable_bind_to_mac}
                        onChange={onPreauthEnabledChange}
                        disabled={setDisableBindToMacResult.isLoading}
                      />
                    }
                    label={<FormattedMessage id="unbind-mac-address" />}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <LoadingButton
                    size="small"
                    loading={cancelResult.isLoading}
                    variant="outlined"
                    color="secondary"
                    onClick={() => setShowMoveDialog(true)}
                  >
                    <FormattedMessage id="move-authorization" />
                  </LoadingButton>
                </TableCell>
              </TableRow>
              <Dialog open={showMoveDialog} onClose={onCloseMoveDialog}>
                <DialogTitle>
                  <FormattedMessage id="move-authorization" />
                </DialogTitle>
                <DialogContent>
                  <Select
                    id="destinationCsId"
                    value={destinationCsId}
                    onChange={onDestinationChange}
                  >
                    {getChargingStations.data?.data.map((cs) => (
                      <MenuItem
                        disabled={
                          !cs.status || cs.status.status !== "Available" || !!cs.authorization
                        }
                        key={cs.id}
                        value={cs.id}
                      >
                        {cs.name}
                      </MenuItem>
                    ))}
                  </Select>
                </DialogContent>
                <DialogActions>
                  <LoadingButton
                    loading={moveAuthResult.isLoading}
                    variant="contained"
                    color="primary"
                    onClick={onMoveClicked}
                  >
                    Move
                  </LoadingButton>
                  <LoadingButton onClick={onCloseMoveDialog} variant="outlined" color="secondary">
                    <FormattedMessage id="cancel" />
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            </Fragment>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CurrentTransactionChip = ({
  cs,
  size,
}: {
  cs: ChargingStation | undefined;
  size?: "small" | "medium";
}) => {
  if (!size) {
    size = "small";
  }
  let color: "info" | "error" | "warning" | "success" = "error";
  let variant: "outlined" | "filled" = "outlined";
  let label: string;

  if (!cs || !cs.current_transaction) {
    label = "Tx None";
    color = "info";
  } else if (cs.current_transaction.charging_state !== "Charging") {
    label = "Tx " + cs.current_transaction.charging_state;
    color = "error";
  } else {
    label = "Tx Charging";
    color = "success";
    variant = "filled";
  }

  return <Chip size={size} variant={variant} color={color} label={label} />;
};

export const AuthorizationStatusSummary = ({ cs }: { cs: ChargingStation | undefined }) => {
  const auth = getAuthorization(cs);
  return (
    <Stack direction={{ xs: "row", md: "column" }} spacing={{ xs: 1, md: "1em" }} useFlexGap>
      {!!cs && <AuthorizationStatusChip auth={auth} size="medium" />}
      <ConnectedVehicleStatusChip cs={cs} size="medium" />
      <CurrentTransactionChip cs={cs} size="medium" />
    </Stack>
  );
};

export const PreauthorizePane = ({
  cs,
  refetch,
}: {
  cs: ChargingStation | undefined;
  refetch: () => void;
}) => {
  const authStatus = getAuthorization(cs)?.status;
  return (
    <Fragment>
      {cs && (!authStatus || ["NotStarted", "Pending"].includes(authStatus)) && (
        <AuthorizeForm cs={cs} refetch={refetch} />
      )}
      {cs && !!authStatus && ["Pending", "Authorized"].includes(authStatus) && (
        <PreauthorizeStatusForm cs={cs} refetch={refetch} />
      )}
    </Fragment>
  );
};

export const getAuthorization = (cs: ChargingStation | undefined) => {
  return cs?.authorization;
};

export const isPreauthorized = (cs: ChargingStation) => {
  return !!getAuthorization(cs);
};
