import axios, { AxiosHeaders } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { sec } from "utils/security";

export const useDownload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const ref = useRef<HTMLAnchorElement>(null);

  const download = useCallback(
    async (url: string) => {
      setIsLoading(true);
      const requestHeaders = new AxiosHeaders();
      if (url.includes(process.env.REACT_APP_API_BASE_URL!!)) {
        const token = await sec.getAccessTokenSilently();
        requestHeaders["Authorization"] = `Bearer ${token}`;
      }
      const { data, headers } = await axios.get<Blob>(url, {
        headers: requestHeaders,
      });
      const fileName = headers["content-disposition"]?.split("filename=")[1];
      setFileName(fileName);

      const contentType = headers["content-type"]?.toString();
      const props = contentType ? { type: contentType } : undefined;
      const blobUrl = window.URL.createObjectURL(new Blob([data], props));
      setDownloadUrl(blobUrl);
    },
    [setDownloadUrl, setFileName, setIsLoading]
  );

  useEffect(() => {
    if (downloadUrl) {
      ref.current?.setAttribute("href", downloadUrl);
      if (fileName) {
        ref.current?.setAttribute("download", fileName);
      }
      ref.current?.click();
      window.URL.revokeObjectURL(downloadUrl);
      setIsLoading(false);
    }
  }, [downloadUrl, ref, fileName, setIsLoading]);

  return { download, isLoading, ref };
};

const useApiDownload = () => {
  const { download, isLoading, ref } = useDownload();

  const apiDownload = useCallback(
    async (path: string) => {
      const url = `${process.env.REACT_APP_API_BASE_URL}/${path}`;
      download(url);
    },
    [download]
  );

  return { download: apiDownload, isLoading, ref };
};

export default useApiDownload;
