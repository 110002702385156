import { createSlice } from "@reduxjs/toolkit";

// types
import { SnackbarProps } from "types/snackbar";

const initialState: SnackbarProps = {
  action: false,
  open: false,
  title: undefined,
  message: "",
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  variant: "default",
  alert: {
    color: "primary",
    variant: "filled",
  },
  transition: "Fade",
  close: true,
  actionButton: false,
  autohideDuration: 6000,
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const snackbar = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar(state, action) {
      const {
        open,
        title,
        message,
        anchorOrigin,
        variant,
        alert,
        transition,
        close,
        actionButton,
        autohideDuration,
      } = action.payload;

      state.action = !state.action;
      state.open = open || initialState.open;
      state.title = title || initialState.title;
      state.message = message || initialState.message;
      state.anchorOrigin = anchorOrigin || initialState.anchorOrigin;
      state.variant = variant || initialState.variant;
      state.alert = {
        color: alert?.color || initialState.alert.color,
        variant: alert?.variant || initialState.alert.variant,
      };
      state.autohideDuration = autohideDuration || initialState.autohideDuration;
      state.transition = transition || initialState.transition;
      state.close = close === false ? close : initialState.close;
      state.actionButton = actionButton || initialState.actionButton;
    },

    openSnackbarSuccess(state, action) {
      const {
        open,
        title,
        message,
        anchorOrigin,
        variant,
        alert,
        transition,
        close,
        actionButton,
        autohideDuration,
      } = action.payload;

      state.action = !state.action;
      state.open = open === false ? open : true;
      state.title = title || initialState.title;
      state.message = message || initialState.message;
      state.anchorOrigin = anchorOrigin || initialState.anchorOrigin;
      state.variant = variant || "alert";
      state.alert = {
        color: alert?.color || "success",
        variant: alert?.variant || "filled",
      };
      state.autohideDuration = autohideDuration || initialState.autohideDuration;
      state.transition = transition || initialState.transition;
      state.close = close === false ? close : initialState.close;
      state.actionButton = actionButton || initialState.actionButton;
    },

    openSnackbarError(state, action) {
      const {
        open,
        title,
        message,
        anchorOrigin,
        variant,
        alert,
        transition,
        close,
        actionButton,
        autohideDuration,
      } = action.payload;

      state.action = !state.action;
      state.open = open === false ? open : true;
      state.title = title || initialState.title;
      state.message = message || initialState.message;
      state.anchorOrigin = anchorOrigin || initialState.anchorOrigin;
      state.variant = variant || "alert";
      state.alert = {
        color: alert?.color || "error",
        variant: alert?.variant || "filled",
      };
      state.autohideDuration = autohideDuration || initialState.autohideDuration;
      state.transition = transition || initialState.transition;
      state.close = close === false ? close : initialState.close;
      state.actionButton = actionButton || initialState.actionButton;
    },

    closeSnackbar(state) {
      state.open = false;
    },
  },
});

export default snackbar.reducer;

export const { closeSnackbar, openSnackbar, openSnackbarSuccess, openSnackbarError } =
  snackbar.actions;
