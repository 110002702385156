import {
  Alert,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import TransactionVehicleInfo from "pages/transactions/components/TransactionVehicleInfo";
import { FormattedMessage } from "react-intl";
import { ChargingStation, LastMeterValues, TransactionStopCondition } from "service/api-slice";
import { formatCents } from "utils/currency";
import ChargingStationStatusIndicator from "./ChargingStationStatusIndicator";
import PumpStateChip from "./PumpStateChip";
import TemperaturesChip from "./TemperaturesChip";

const FormatSoCSummary = ({
  lastMeterValues,
  stopCondition,
}: {
  lastMeterValues?: LastMeterValues | null;
  stopCondition?: TransactionStopCondition;
}) => {
  if (!lastMeterValues) {
    return <Typography variant="body1">{"-"}</Typography>;
  }
  if (lastMeterValues.time_remaining !== 600) {
    if (stopCondition?.state_of_charge) {
      return (
        <FormattedMessage
          id="soc-limit-time-remaining"
          values={{
            soc: lastMeterValues.soc,
            limit: stopCondition.state_of_charge,
            time: lastMeterValues.time_remaining,
          }}
        />
      );
    }
    return (
      <FormattedMessage
        id="soc-time-remaining"
        values={{
          soc: lastMeterValues?.soc,
          time: lastMeterValues?.time_remaining,
        }}
      />
    );
  }
  if (stopCondition?.state_of_charge) {
    return (
      <FormattedMessage
        id="soc-value-limit"
        values={{
          soc: lastMeterValues.soc,
          limit: stopCondition.state_of_charge,
        }}
      />
    );
  }
  return (
    <FormattedMessage
      id="soc-value"
      values={{
        soc: lastMeterValues?.soc,
      }}
    />
  );
};

const OccupiedChargingStationTableRow = ({
  chargingStation,
  onClick,
}: {
  chargingStation: ChargingStation;
  onClick: (cs: ChargingStation) => void;
}) => {
  const lastMeterValues = chargingStation.current_transaction?.last_meter_values;
  const stopConditions = chargingStation.authorization?.stop_conditions;

  return (
    <TableRow hover={true} sx={{ cursor: "pointer" }} onClick={() => onClick(chargingStation)}>
      <TableCell>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Typography variant="h5">{chargingStation.name}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" justifyContent="flex-end">
              <ChargingStationStatusIndicator chargingStation={chargingStation} />
            </Box>
          </Grid>
          {chargingStation.current_transaction && (
            <Grid item xs={12}>
              <TransactionVehicleInfo transaction={chargingStation.current_transaction} />
            </Grid>
          )}
          <Grid item xs={6}>
            <FormatSoCSummary lastMeterValues={lastMeterValues} stopCondition={stopConditions} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <TemperaturesChip tempN={lastMeterValues?.temp_n} tempP={lastMeterValues?.temp_p} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {lastMeterValues?.power ? <>{lastMeterValues?.power / 1000}kW</> : <>{"-"}</>}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <PumpStateChip cooling={chargingStation.pump_state} />
          </Grid>
          {lastMeterValues?.price_in_cents && (
            <Grid item xs={6}>
              <Typography variant="body1">
                {formatCents(lastMeterValues.price_in_cents)}
                {stopConditions?.cost_in_cents
                  ? " / " + formatCents(stopConditions.cost_in_cents)
                  : ""}
              </Typography>
            </Grid>
          )}
          {chargingStation.status?.errors?.map((error) => (
            <Grid item key={`${chargingStation.id}-error-${error.code}`} xs={12}>
              <Alert severity="warning">
                ERR{error.code} : {error.name} - {error.common_causes.join("; ")}
              </Alert>
            </Grid>
          ))}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

const OccupiedChargingStationsMobile = ({
  chargingStations,
  onClick,
}: {
  chargingStations: ChargingStation[];
  onClick: (cs: ChargingStation) => void;
  siteId?: string | null;
}) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {chargingStations.map((chargingStation) => (
            <OccupiedChargingStationTableRow
              key={chargingStation.id}
              chargingStation={chargingStation}
              onClick={onClick}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OccupiedChargingStationsMobile;
