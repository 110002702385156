import { ListVehiclesArgs, useListVehiclesQuery, Vehicle } from "service/api-slice";

import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import GravityTable from "components/table/GravityTable";
import { useNavigateWithQuery } from "hooks/useNavigateWithQuery";
import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useModuleContext } from "types/context";

const columnHelper = createColumnHelper<Vehicle>();
const columns = [
  columnHelper.accessor("owner", {
    cell: (i) => i.getValue()?.display_name ?? "",
    header: () => <FormattedMessage id="owner" />,
  }),
  columnHelper.accessor("mac_address", {
    cell: (i) => i.getValue() ?? "-",
    header: () => <FormattedMessage id="mac-address" />,
  }),
  columnHelper.accessor("vin", {
    cell: (i) => i.getValue() ?? "-",
    header: () => <FormattedMessage id="vin" />,
  }),
  columnHelper.accessor("color", {
    cell: (i) => i.getValue() ?? "-",
    header: () => <FormattedMessage id="color" />,
  }),
  columnHelper.accessor("year", {
    cell: (i) => i.getValue() ?? "-",
    header: () => <FormattedMessage id="year" />,
  }),
  columnHelper.accessor("make", {
    cell: (i) => i.getValue() ?? "-",
    header: () => <FormattedMessage id="make" />,
  }),
  columnHelper.accessor("model", {
    cell: (i) => i.getValue() ?? "-",
    header: () => <FormattedMessage id="model" />,
  }),
  columnHelper.accessor("license_plate_state", {
    cell: (i) => i.getValue() ?? "-",
    header: () => <FormattedMessage id="license_plate_state" />,
  }),
  columnHelper.accessor("license_plate", {
    cell: (i) => i.getValue() ?? "-",
    header: () => <FormattedMessage id="license_plate" />,
  }),
];

export const VehicleTable = () => {
  const navigate = useNavigateWithQuery();
  const { filter, module } = useModuleContext();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  const args = useMemo<ListVehiclesArgs>(() => {
    return {
      page: pagination.pageIndex,
      count: pagination.pageSize,
      orgId: filter.orgId,
      module: module,
    };
  }, [pagination, filter.orgId, module]);

  const { data, isLoading } = useListVehiclesQuery(args);

  const onClick = (vehicle: Vehicle) => {
    navigate(`${vehicle.id}`);
  };

  return (
    <GravityTable<Vehicle>
      id="vt"
      columns={columns}
      data={data?.data}
      isLoading={isLoading}
      rowClickHandlerProvider={() => {
        return onClick;
      }}
      pagination={pagination}
      setPagination={setPagination}
      pageCount={data?.meta.pagination.page_count}
    />
  );
};
