import { TransactionReport } from "service/api-slice";

import { DownloadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { createColumnHelper } from "@tanstack/react-table";
import GravityTable from "components/table/GravityTable";
import { useDownload } from "hooks/useDownload";
import { FormattedMessage } from "react-intl";

const monthToInt = (month: string) => {
  switch (month) {
    case "January":
      return 1;
    case "February":
      return 2;
    case "March":
      return 3;
    case "April":
      return 4;
    case "May":
      return 5;
    case "June":
      return 6;
    case "July":
      return 7;
    case "August":
      return 8;
    case "September":
      return 9;
    case "October":
      return 10;
    case "November":
      return 11;
    case "December":
      return 12;
    default:
      return 0;
  }
};

const ReportDownloadButton = ({ url }: { url: string }) => {
  const { download, isLoading, ref } = useDownload();
  return (
    <>
      <LoadingButton
        onClick={() => download(url)}
        loading={isLoading}
        startIcon={<DownloadOutlined />}
      >
        <FormattedMessage id="download" />
      </LoadingButton>
      <a href="#download" style={{ display: "none" }} ref={ref}>
        download
      </a>
    </>
  );
};

const columnHelper = createColumnHelper<TransactionReport>();
const columns = [
  columnHelper.accessor("year", {
    cell: (i) => i.getValue() ?? "-",
    header: () => <FormattedMessage id="year" />,
  }),
  columnHelper.accessor("month", {
    cell: (i) => i.getValue() ?? "-",
    header: () => <FormattedMessage id="month" />,
    sortingFn: (a, b) => monthToInt(a.original.month) - monthToInt(b.original.month),
  }),
  columnHelper.accessor("url", {
    cell: (i) => <ReportDownloadButton url={i.getValue()} />,
    header: () => "",
    enableSorting: false,
  }),
];

export const TransactionReportsTable = ({
  reports,
  isLoading,
}: {
  reports: TransactionReport[];
  isLoading: boolean;
}) => {
  return (
    <GravityTable<TransactionReport>
      id="utrt"
      columns={columns}
      data={reports}
      isLoading={isLoading}
      initialSorting={[
        { id: "year", desc: true },
        { id: "month", desc: true },
      ]}
    />
  );
};
