import { MenuItem, Select } from "@mui/material";
import { useCallback } from "react";
import { useListFleetsQuery } from "service/api-slice";

const FleetSelector = ({
  organizationId,
  fleetId,
  setFleetId,
}: {
  organizationId?: string;
  fleetId: string;
  setFleetId: (fleetId: string) => void;
}) => {
  const listFleetsQuery = useListFleetsQuery(organizationId);
  const onSelectFleet = useCallback((e) => setFleetId(e.target.value), [setFleetId]);
  return (
    <Select
      id="fleet"
      value={fleetId}
      placeholder="Choose fleet"
      disabled={!listFleetsQuery.isSuccess || listFleetsQuery.data?.data.length === 0}
      onChange={onSelectFleet}
      fullWidth={true}
    >
      {listFleetsQuery.isSuccess &&
        listFleetsQuery.data.data.map((fleet) => (
          <MenuItem key={fleet.id} value={fleet.id}>
            {fleet.name} - {fleet.id}
          </MenuItem>
        ))}
    </Select>
  );
};

export default FleetSelector;
