import { ReactNode, useMemo } from "react";

// material-ui
import { AppBar, AppBarProps, Chip, Toolbar, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project import
import IconButton from "components/@extended/IconButton";
import AppBarStyled from "./AppBarStyled";
import HeaderContent from "./HeaderContent";

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useMe } from "contexts/GravityUserContext";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const Header = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));
  const user = useMe();

  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);

  const iconBackColorOpen = theme.palette.mode === "dark" ? "grey.200" : "grey.300";
  const iconBackColor = theme.palette.mode === "dark" ? "background.default" : "grey.100";

  // common header
  const mainHeader: ReactNode = (
    <Toolbar>
      <IconButton
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        color="secondary"
        variant="light"
        sx={{
          color: "text.primary",
          bgcolor: open ? iconBackColorOpen : iconBackColor,
          ml: { xs: 0, lg: -2 },
        }}
      >
        {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </IconButton>
      {process.env.REACT_APP_ENV !== "production" && user.organization.slug === "gravity" && (
        <Chip
          label={process.env.REACT_APP_ENV!!.toUpperCase()}
          color="error"
          variant="outlined"
          sx={{ marginLeft: 1, height: 36 }}
        />
      )}
      {headerContent}
    </Toolbar>
  );

  // app-bar params
  const appBar: AppBarProps = {
    position: "fixed",
    color: "inherit",
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: 1200,
      // boxShadow: theme.customShadows.z1
    },
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

export default Header;
