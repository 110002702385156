import { Chip } from "@mui/material";

const TemperaturesChip = ({ tempN, tempP }: { tempN?: number; tempP?: number }) => {
  if (tempP && tempN) {
    if (tempP >= 88 || tempN >= 88) {
      return <Chip size="small" color="error" label={`${tempP}ºC / ${tempN}ºC`} />;
    }
    if (tempP >= 80 || tempN >= 80) {
      return <Chip size="small" color="warning" label={`${tempP}ºC / ${tempN}ºC`} />;
    }
    return <>{`${tempP}ºC / ${tempN}ºC`}</>;
  }
  return <>{"-"}</>;
};

export default TemperaturesChip;
