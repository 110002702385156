import {
  CarOutlined,
  DashboardOutlined,
  ExclamationCircleOutlined,
  FileTextOutlined,
  FileZipOutlined,
  PictureOutlined,
  ProfileOutlined,
  ThunderboltOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { Flags } from "experimentation/Flags";
import { FormattedMessage } from "react-intl";
import { NavItemType } from "types/menu";

export const SiteOperatorMenu: NavItemType = {
  id: "site-operator-menu",
  type: "group",
  url: "/site-operator",
  title: <FormattedMessage id="site-operator-menu" />,
  breadcrumbs: true,
  children: [
    {
      id: "dashboard",
      title: <FormattedMessage id="dashboard" />,
      type: "item",
      url: "/site-operator",
      icon: DashboardOutlined,
      breadcrumbs: false,
    },
    {
      id: "charging-stations",
      title: <FormattedMessage id="charging-stations" />,
      type: "item",
      url: "/site-operator/charging-stations",
      icon: ThunderboltOutlined,
      breadcrumbs: true,
      children: [
        {
          id: "charging-station-details",
          title: "Charging Station Details",
          breadcrumbs: true,
          url: ":id",
          type: "item",
        },
      ],
    },
    {
      id: "charge-points",
      title: <FormattedMessage id="charge-points" />,
      type: "item",
      url: "/site-operator/charge-points",
      icon: ThunderboltOutlined,
      breadcrumbs: true,
      children: [
        {
          id: "charge-point-details",
          title: "Charge Point Details", //<FormattedMessage id="charge-points" />,
          breadcrumbs: true,
          url: ":id",
          type: "item",
        },
      ],
    },
    {
      id: "charging-sessions",
      title: <FormattedMessage id="sessions" />,
      type: "item",
      url: "/site-operator/charging-sessions",
      icon: ProfileOutlined,
      breadcrumbs: true,
    },
    {
      id: "vehicles",
      title: <FormattedMessage id="vehicles" />,
      type: "item",
      url: "/site-operator/vehicles",
      icon: CarOutlined,
      breadcrumbs: true,
    },
    {
      id: "parking",
      title: <FormattedMessage id="parking" />,
      type: "item",
      icon: CarOutlined,
      breadcrumbs: true,
      url: "/site-operator/parking",
      children: [
        {
          id: "parking-session-details",
          title: "Parking Session",
          breadcrumbs: true,
          url: ":id",
          type: "item",
        },
      ],
    },
    {
      id: "cooling",
      title: <FormattedMessage id="pumps-management" />,
      type: "item",
      url: "/site-operator/pump-stations",
      icon: ProfileOutlined,
      breadcrumbs: true,
    },
    {
      id: "vehicle-images",
      title: <FormattedMessage id="vehicle-images" />,
      type: "item",
      url: "/site-operator/vehicle-images",
      icon: PictureOutlined,
      visible: Flags.VehicleImageUpload,
      breadcrumbs: true,
    },
    {
      id: "reports",
      title: <FormattedMessage id="reports" />,
      type: "collapse",
      icon: ProfileOutlined,
      breadcrumbs: true,
      children: [
        {
          id: "details",
          title: <FormattedMessage id="reports-details" />,
          type: "item",
          url: "/site-operator/reports/detail",
          icon: ProfileOutlined,
          breadcrumbs: true,
        },
        {
          id: "sales-tax",
          title: <FormattedMessage id="sales-tax" />,
          type: "item",
          url: "/site-operator/reports/sales-tax",
          icon: ProfileOutlined,
          breadcrumbs: true,
        },
        {
          id: "transaction-failures",
          title: <FormattedMessage id="transaction-failures" />,
          type: "item",
          url: "/site-operator/reports/failures",
          icon: ExclamationCircleOutlined,
          breadcrumbs: true,
        },
        {
          id: "charging-station-faults",
          title: <FormattedMessage id="charging-station-faults" />,
          type: "item",
          url: "/site-operator/reports/faults",
          icon: ExclamationCircleOutlined,
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "maintenance",
      title: <FormattedMessage id="maintenance" />,
      type: "collapse",
      icon: ToolOutlined,
      breadcrumbs: true,
      children: [
        {
          id: "maintenance-logs",
          title: <FormattedMessage id="maintenance-log-requests" />,
          type: "item",
          url: "/site-operator/maintenance/log-requests",
          icon: FileTextOutlined,
          breadcrumbs: true,
        },
        {
          id: "maintenance-plans",
          title: <FormattedMessage id="maintenance-firmware-updates" />,
          type: "item",
          url: "/site-operator/maintenance/firmware-updates",
          icon: FileZipOutlined,
          breadcrumbs: true,
        },
      ],
    },
  ],
};
