import { ChangeEvent, useCallback, useMemo, useState } from "react";

export interface NameAndContents {
  readonly name: string;
  readonly contents: Buffer;
}

export const useFilePicker = (onFilesRead?: (files: NameAndContents[]) => void) => {
  const [files, setFiles] = useState<NameAndContents[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onFilesPicked = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      setFiles([]); // Clear loaded files.
      setIsLoading(true);

      if (!e.target.files || e.target.files.length === 0) {
        return;
      }

      const encodedFiles: NameAndContents[] = [];
      for await (const encodedFile of Array.from(e.target.files).map((file) => {
        return file.arrayBuffer().then((arrayBuffer) => {
          return {
            name: file.name,
            contents: Buffer.from(arrayBuffer),
          };
        });
      })) {
        encodedFiles.push(encodedFile);
      }

      if (onFilesRead) {
        onFilesRead(encodedFiles);
      }

      setFiles(encodedFiles);
      setIsLoading(false);
    },
    [setFiles, setIsLoading, onFilesRead]
  );

  const result = useMemo(() => {
    return {
      files: files,
      onFilesPicked: onFilesPicked,
      clearFilesPicked: () => setFiles([]),
      isLoading: isLoading,
    };
  }, [files, isLoading, onFilesPicked, setFiles]);

  return result;
};
