import { Skeleton, TableCell, TableRow } from "@mui/material";

const TableRowSkeleton = ({
  columnCount,
  rowCount = 10,
  isLoading,
}: {
  columnCount: number;
  rowCount: number;
  isLoading: boolean;
}) => {
  if (!isLoading) {
    return null;
  }

  const rows: JSX.Element[] = [];
  const cols: JSX.Element[] = [];
  for (let i = 0; i < columnCount; i++) {
    cols.push(
      <TableCell key={`loading-cell-${i}`}>
        <Skeleton />
      </TableCell>
    );
  }

  for (let i = 0; i < rowCount; i++) {
    rows.push(<TableRow key={`loading-row-${i}`}>{cols}</TableRow>);
  }

  return <>{rows}</>;
};

export default TableRowSkeleton;
