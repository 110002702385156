import { useTheme } from "@mui/material/styles";
import AnalyticsDataCard from "components/cards/statistics/AnalyticsDataCard";
import useConfig from "hooks/useConfig";
import { useMemo } from "react";
import ReactApexChart, { Props as ChartProps } from "react-apexcharts";
import {
  ChargingStation,
  ChargingStationFaultData,
  useGetChargingStationFaultsQuery,
} from "service/api-slice";

// ==============================|| USER CARD CHART ||============================== //

const ChargingStationFaults = (props: {
  args: { startTime: string; endTime: string; siteId?: string; orgId?: string };
  chargingStations?: ChargingStation[];
}) => {
  const theme = useTheme();
  const { mode } = useConfig();

  const { data } = useGetChargingStationFaultsQuery({
    ...props.args,
    page: 0,
    count: 500,
  });

  const totalFaultTime = useMemo(() => {
    if (!data) {
      return 0;
    }

    return data.data.reduce(
      (acc, v) => acc + new Date(v.end_time).valueOf() - new Date(v.start_time).valueOf(),
      0
    );
  }, [data]);

  const totalTime = useMemo(() => {
    return (
      (new Date(props.args.endTime).valueOf() - new Date(props.args.startTime).valueOf()) *
      (props.chargingStations?.length ?? 1)
    );
  }, [props.args.endTime, props.args.startTime, props.chargingStations]);

  const faultRate = useMemo(() => {
    if (totalFaultTime === 0) {
      // Returning undefined here to ensure the percentage is not displayed.
      return;
    }

    return parseFloat((totalFaultTime / totalTime).toFixed(5));
  }, [totalFaultTime, totalTime]);

  const count = useMemo(() => {
    return data?.data.length ?? 0;
  }, [data]);

  const series = useMemo(() => {
    if (!data) {
      return;
    }

    const dict = data.data.reduce((acc: Map<string, number>, f: ChargingStationFaultData) => {
      const date = new Date(f.start_time).toDateString();
      if (!acc.get(date)) {
        acc.set(date, 0);
      }
      acc.set(date, acc.get(date)! + 1);
      return acc;
    }, new Map<string, number>());

    var series: { x: string; y: number }[] = [];
    for (
      var d = new Date(props.args.startTime);
      d <= new Date(props.args.endTime);
      d = new Date(new Date(d).valueOf() + 1000 * 60 * 60 * 24)
    ) {
      series.push({ x: d.toDateString(), y: dict.get(d.toDateString()) ?? 0 });
    }

    return [
      {
        name: "Charging Station Faults",
        type: "bar",
        data: series,
      },
    ];
  }, [data, props.args]);

  // chart options
  const areaChartOptions = useMemo<ChartProps>(() => {
    return {
      colors: [
        theme.palette.primary.main,
        theme.palette.primary[700],
        theme.palette.secondary.main,
      ],
      stroke: {
        show: [false],
        width: [0],
      },
      chart: {
        id: "faults-chart",
        height: 100,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: "numeric",
        tickPlacement: ["on"],
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        tooltip: {
          enabled: false,
        },
        labels: {
          show: true,
          formatter: (value: string) => {
            let date = new Date(value);
            return `${date.getMonth() + 1}/${date.getDate()}`;
          },
        },
      },
      yaxis: {
        show: false,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        theme: mode === "dark" ? "dark" : "light",
        x: {
          show: true,
        },
        y: {
          formatter: (value: number) => {
            return value.toFixed(0);
          },
        },
      },
      grid: {
        show: false,
      },
    };
  }, [mode, theme]);

  if (!series) {
    return null;
  }

  return (
    <AnalyticsDataCard
      title="charging-station-faults"
      count={`${count} Faults`}
      percentage={faultRate}
      color="secondary"
    >
      <ReactApexChart options={areaChartOptions} series={series} height={200} />
    </AnalyticsDataCard>
  );
};

export default ChargingStationFaults;
