import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import FleetSelector from "components/FleetSelector";
import StateSelector from "components/StateSelector";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Vehicle, useUpdateVehicleMutation } from "service/api-slice";
import { dispatch } from "store";
import { openSnackbarSuccess } from "store/reducers/snackbar";
import isValidEmail from "utils/email";
import OwnerSelector from "./components/OwnerSelector";

const EditVehicle = ({ vehicle: defaultVehicle, done }: { vehicle: Vehicle; done: () => void }) => {
  const [vehicle, setVehicle] = useState<Vehicle>(defaultVehicle);
  const onEmailChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const email = e.target.value;
      setVehicle((vehicle) => {
        return { ...vehicle, owner_email: email };
      });
    },
    [setVehicle]
  );
  const emailError = useMemo(() => {
    return !!vehicle.owner_email && !isValidEmail(vehicle.owner_email);
  }, [vehicle]);

  const [updateVehicle, updateVehicleResult] = useUpdateVehicleMutation();
  const [error, setError] = useState<string | null>(null);

  const submit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      var color = vehicle.color?.trim()?.toUpperCase();
      if (color?.length === 0) {
        color = undefined;
      }

      var license_plate = vehicle.license_plate?.trim()?.toUpperCase();
      if (license_plate?.length === 0) {
        license_plate = undefined;
      }

      var license_plate_state = vehicle.license_plate_state ?? undefined;
      if (license_plate_state?.length === 0) {
        license_plate_state = undefined;
      }

      updateVehicle({
        vehicle_id: vehicle.id,
        fleet_id: vehicle.fleet_id,
        color: color,
        license_plate: license_plate,
        license_plate_state: license_plate_state,
        owner_id: vehicle.owner?.id,
        owner_email: vehicle.owner_email,
      });
    },
    [vehicle, updateVehicle]
  );

  const intl = useIntl();
  useEffect(() => {
    if (updateVehicleResult.isSuccess) {
      done();
      dispatch(
        openSnackbarSuccess({
          message: intl.formatMessage({ id: "vehicle-updated" }),
        })
      );
    }
    if (updateVehicleResult.isError) {
      console.error(updateVehicleResult.error);
      setError((updateVehicleResult.error as any).data.message);
    }
  }, [intl, updateVehicleResult, done]);

  return (
    <form onSubmit={submit}>
      {error && (
        <Alert severity="error" sx={{ ml: 2, mr: 2 }}>
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow hover={false}>
              <TableCell>
                <FormattedMessage id="description" />
              </TableCell>
              <TableCell>{vehicle.description}</TableCell>
            </TableRow>
            <TableRow hover={false}>
              <TableCell>
                <FormattedMessage id="color" />
              </TableCell>
              <TableCell>
                <TextField
                  name="color"
                  fullWidth={true}
                  value={vehicle.color ?? ""}
                  onChange={(e) =>
                    setVehicle((vehicle) => {
                      return { ...vehicle, color: e.target.value };
                    })
                  }
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </TableCell>
            </TableRow>
            <TableRow hover={false}>
              <TableCell>
                <FormattedMessage id="license-plate" />
              </TableCell>
              <TableCell>
                <Stack direction="row" spacing={2}>
                  <StateSelector
                    onStateChange={(selectedState) => {
                      setVehicle({ ...vehicle, license_plate_state: selectedState?.value });
                    }}
                    defaultValue={vehicle.license_plate_state ?? ""}
                  />
                  <TextField
                    sx={{ width: 150 }}
                    name="license_plate"
                    value={vehicle.license_plate ?? ""}
                    onChange={(e) =>
                      setVehicle((vehicle) => {
                        return { ...vehicle, license_plate: e.target.value };
                      })
                    }
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow hover={false}>
              <TableCell>
                <FormattedMessage id="owner" />
              </TableCell>
              <TableCell>
                <OwnerSelector
                  defaultValue={vehicle.owner}
                  onOwnerChange={(owner) => {
                    setVehicle((vehicle) => {
                      return { ...vehicle, owner };
                    });
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow hover={false}>
              <TableCell>
                <FormattedMessage id="vin" />
              </TableCell>
              <TableCell>{vehicle.vin}</TableCell>
            </TableRow>
            <TableRow hover={false}>
              <TableCell>
                <FormattedMessage id="mac-address" />
              </TableCell>
              <TableCell>{vehicle.mac_address}</TableCell>
            </TableRow>
            {vehicle?.owner?.type === "organization" && (
              <TableRow hover={false}>
                <TableCell>
                  <FormattedMessage id="fleet" />
                </TableCell>
                <TableCell>
                  <FleetSelector
                    organizationId={vehicle!.owner!.id}
                    fleetId={vehicle!.fleet_id ?? ""}
                    setFleetId={(fleetId: string) => {
                      setVehicle((vehicle) => {
                        return { ...vehicle, fleet_id: fleetId };
                      });
                    }}
                  />
                </TableCell>
              </TableRow>
            )}
            <TableRow hover={false}>
              <TableCell>
                <FormattedMessage id="email" />
              </TableCell>
              <TableCell>
                <TextField
                  id="email"
                  size="small"
                  fullWidth
                  value={vehicle.owner_email ?? ""}
                  onChange={onEmailChange}
                  error={emailError}
                  {...(emailError
                    ? { helperText: <FormattedMessage id="enter-valid-email" /> }
                    : {})}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" spacing={1} justifyContent={"flex-end"} sx={{ mr: 2 }}>
        <LoadingButton type="submit" variant="contained" loading={updateVehicleResult.isLoading}>
          <FormattedMessage id="save" />
        </LoadingButton>
        <Button variant="outlined" onClick={done}>
          <FormattedMessage id="cancel" />
        </Button>
      </Stack>
    </form>
  );
};

export default EditVehicle;
