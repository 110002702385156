import Grid from "@mui/material/Grid";
import MainCard from "components/MainCard";
import { useIntl } from "react-intl";
import { useUtilityListTransactionReportsQuery } from "service/api-slice";
import { TransactionReportsTable } from "../components/TransactionReportsTable";

const DefaultDashboard = () => {
  const { data, isLoading } = useUtilityListTransactionReportsQuery();
  const intl = useIntl();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <MainCard content={false} title={intl.formatMessage({ id: "transaction-reports" })}>
          <TransactionReportsTable reports={data?.data ?? []} isLoading={isLoading} />
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default DefaultDashboard;
